import React from "react";

function Loader(props) {
  return (
    <i
      id="notificationsIcon"
      className={
        "fa fa-lg fa-spinner fa-pulse fa-fw " +
        (props.orange ? " fa-cog " : "") +
        (" " + props.className)
      }
      aria-hidden="true"
    ></i>
  );
}
Loader.defaultProps = {
  orange: true,
  className: "",
};
export default Loader;
