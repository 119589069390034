import React from "react";
import { withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

class HelpInviteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  componentDidMount() {
    this.outer = document.createElement("div");
    this.outer.style.visibility = "hidden";
    this.outer.id = "HelpInviteModalButton";
    this.outer.type = "button";
    this.outer.addEventListener("click", () => {
      this.toggleModal(true);
    });
    document.body.appendChild(this.outer);
  }

  componentWillUnmount() {
    this.outer.parentNode.removeChild(this.outer);
  }

  toggleModal = (bool) => {
    this.setState({ isOpen: bool });
    if (bool) {
      window.history.pushState("popup-open", null, "");
      window.onpopstate = (e) => {
        e.preventDefault();
        this.setState({ isOpen: false });
        window.onpopstate = null;
      }
    } else {
      if(window.history.state === "popup-open")
        window.history.back();
    }
  };

  render() {
    return (
      <Modal
        show={this.state.isOpen}
        onHide={() => this.toggleModal(false)}
        className="left"
        dialogClassName="modal-left-side modal-bottom-left modal-log-dialog"
        backdrop={false}
        keyboard={false}
      >
        <div className="modal-body text-center">
          <div className="modal-header no-borders">
            <div className="thipi-text-h2 pb-2">
              Complimenti hai ricevuto il tuo primo invito
            </div>
            <button
              type="button"
              className="close modal-close position-absolute modal-close-top-right"
              onClick={() => this.toggleModal(false)}
            >
              ×
            </button>
          </div>

          <div className="thipi-text-h4">
            Qualcuno ha utilizzato la funzione contatta dopo
            aver visitato il tuo profilo :) <br/> Scegli l&apos;orario che preferisci nel calendario ed avrai un{" "}
            <b className="text-thipibo-color">appuntamento</b> per la tua
            visita.
          </div>
        </div>
        <div className="modal-footer text-right">
          <button
            className="btn thipi-btn-color mini-btn mx-1"
            onClick={() => this.toggleModal(false)}
          >
            Ho capito
          </button>
        </div>
      </Modal>
    );
  }
}

export default withRouter(HelpInviteModal);
