import React from "react";
import PropTypes from "prop-types";

export default class RefreshAtEnd extends React.Component {
  static propTypes = {
    root: PropTypes.string, //.isRequired, // list container
    onVisible: PropTypes.func, // callback to call when loading is visible
  };

  static defaultProps = {
    onVisible: () => {},
  };

  constructor(prop) {
    super(prop);
    this.lastRef = React.createRef();
  }

  componentDidMount() {
    this.rootElem = document.getElementById(this.props.root);
    if (!this.lastRef.current) return;
    this.observer = new window.IntersectionObserver(this.interSectionCallback, {
      threshold: [1],
      //root: (this.rootElem ? this.rootElem : null),
      //rootMargin: (this.rootElem ? '0px' : undefined),
    });
    this.observer.observe(this.lastRef.current);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  interSectionCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio === 1) {
        this.props.onVisible();
      }
    });
  };

  render() {
    return (
      <div ref={this.lastRef} className="animation">
        {this.props.children}
      </div>
    );
  }
}
