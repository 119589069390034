import {
  SET_RESEARCH,
  RESET_RESEARCH,
  SET_ADVERTISE,
  SET_PING,
  SET_ADS,
  CLEAR_ADVERTISE,
} from "../constants/action-advertise-types";
import { RESET_REDUX } from "../constants/action-general-types";
import { produce } from "immer";

const initialState = {
  object: {}, // utenti indicizzati per user_id
  research: {
    //advertise_id: -1,
    //age: {},
    //preferences: {},
    ids: [],
  },
};

const advertiseReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    // eslint-disable-next-line default-case
    switch (type) {
      case SET_RESEARCH:
        //console.log(type, payload);
        //draft.research.advertise_id = payload.advertise_id;
        //draft.research.age = payload.age;
        //draft.research.preferences = payload.preferences;
        draft.research.ids = payload.ids;
        return;
      case SET_ADVERTISE:
        draft.object[payload.advertise._id] = payload;
        return;
      case SET_PING:
        //console.log(type, payload);
        if (payload.pinged) {
          draft.object[payload.advertise_id].advertise.likes.push(
            "" + payload.user_id
          );
        } else {
          draft.object[payload.advertise_id].advertise.likes = state.object[
            payload.advertise_id
          ].advertise.likes.filter((x) => x != "" + payload.user_id);
        }
        return;
      case SET_ADS:
        payload.forEach((element) => {
          draft.object[element.advertise._id] = element;
        });
        return;
      case CLEAR_ADVERTISE:
        return initialState;
      case RESET_RESEARCH:
        draft.research.ids = [];
        return;
      case RESET_REDUX:
        return initialState;
    }
  });
export default advertiseReducer;
