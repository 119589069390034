import {
  SET_USER,
  SET_LOGGED,
  SET_AGENDA,
  SET_NUM_SUPERPING,
  SET_NOTE,
  //CHANGE_INFO,
  CHANGE_RESEARCH_INFO,
  CHANGE_PERSONAL_INFO,
  CHANGE_PERSONAL_ELEMENTS,
  CHANGE_CURR_COND,
  CHANGE_TENANTS_NUMBER,
  //CHANGE_TENANT,
  CHANGE_IMAGE,
  CHANGE_COVER_IMAGE,
  CHANGE_VIDEO,
  CHANGE_TEMPLATE,
  CHANGE_SETTINGS,
  SET_HELP_SEEN,
  SET_INVITE_HELP_SEEN,
  SET_PING_HELP_SEEN,
  SET_SUPERPING_HELP_SEEN,
  SET_SHARE_HELP_SEEN,
  SET_NEW_USER,
  SET_TEMP_HOME,
  SET_PROFILATION,
} from "../constants/action-user-types";
import { RESET_REDUX } from "../constants/action-general-types";
import produce from "immer";

const initialState = {
  _id: 0,
  name: "",
  surname: "",
  mail: "",
  social: "",
  city: "",
  birth: -1,
  gender: "",
  seeking: false,
  profile_image: "",
  profile_image_webp: "",
  cover_image: "",
  cover_image_webp: "",
  active: false,
  subscribed: false,
  subscribedBasic: false,
  profiled: true,
  logged: false,
  terms: true,
  marketing: true,
  email_disabled: false,
  superpingHelpSeen: true,
  pingHelpSeen: true,
  inviteHelpSeen: true,
  shareHelpSeen: true,
  isNotifMailConfirmed: true,
  pwaInstallSeen: true,
  preBookingSeen: true,
  interests: [],
  lifestyles: [],
  sport: -1,
  alcol: -1,
  smoke: -1,
  animals: -1,
  relationship: -1,
  education: -1,
  weekend: -1,
  location: "",
  type: "N",
  studyplace: "",
  workplace: "",
  budget: null,
  from_date: "",
  to_date: "",
  videoId: "",
  alone: true,
  tenants: [],
  zone: [],
  room_types: [],
  description: "",
  requests: [],
  timestamp_home: -1,
  entries: [],
  numSuperping: 0,
  oldestSuperping: 0,
  new: false,
  subscriptions: [],
  messengerContact: { link: null, name: null },
  whatsappContact: { prefix: null, number: null },
  instagramContact: null,
  tiktokContact: null,
  telegramContact: null,
  last_login_date: null,
  tempHome: {},
  template: "",
  lastBestTenants: null,
};

const userReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    let index, date, now;
    const { type, payload } = action;
    // eslint-disable-next-line default-case
    switch (type) {
      case SET_USER:
        date = new Date();
        now = date.getTime();
        var numS = 0;
        if (now - payload.superping1 > 86400000) numS++;
        if (now - payload.superping2 > 86400000) numS++;
        draft._id = payload._id;
        draft.name = payload.name;
        draft.surname = payload.surname;
        draft.active = payload.active;
        draft.subscribed = payload.subscribed;
        draft.subscribedBasic = payload.subscribedBasic;
        draft.logged = payload.logged;
        draft.mail = payload.mail;
        draft.city = payload.city;
        draft.social = payload.social;
        draft.birth = payload.birth;
        draft.gender = payload.gender;
        draft.seeking = payload.seeking;
        draft.email_disabled = payload.email_disabled;
        draft.terms = payload.terms;
        draft.marketing = payload.marketing;
        draft.superpingHelpSeen = payload.superpingHelpSeen;
        draft.pwaInstallSeen = payload.pwaInstallSeen;
        draft.preBookingSeen = payload.preBookingSeen;
        draft.pingHelpSeen = payload.pingHelpSeen;
        draft.inviteHelpSeen = payload.inviteHelpSeen;
        draft.shareHelpSeen = payload.shareHelpSeen;
        draft.isNotifMailConfirmed = payload.isNotifMailConfirmed;
        draft.profile_image = payload.profile_image;
        draft.profile_image_webp = payload.profile_image_webp;
        draft.cover_image = payload.cover_image;
        draft.cover_image_webp = payload.cover_image_webp;
        draft.videoId = payload.videoId;
        draft.lifestyles = payload.lifestyles;
        draft.interests = payload.interests;
        draft.sport = payload.sport;
        draft.weekend = payload.weekend;
        draft.alcol = payload.alcol;
        draft.smoke = payload.smoke;
        draft.animals = payload.animals;
        draft.relationship = payload.relationship;
        draft.education = payload.education;
        draft.location = payload.location;
        draft.type = payload.type;
        draft.studyplace = payload.studyplace;
        draft.workplace = payload.workplace;
        draft.budget = payload.budget;
        draft.from_date = payload.from_date;
        draft.to_date = payload.to_date;
        draft.alone = payload.alone;
        draft.tenants = payload.tenants;
        draft.zone = payload.zone;
        draft.profiled = payload.profiled;
        draft.room_types = payload.room_types;
        draft.description = payload.description;
        draft.requests = payload.requests;
        draft.subscriptions = payload.subscriptions;
        draft.timestamp_home = payload.timestamp_home;
        draft.last_login_date = payload.last_login_date;
        draft.template = payload.template;
        if (payload.tempHome) draft.tempHome = payload.tempHome;
        if (payload.messengerContact)
          draft.messengerContact = payload.messengerContact;
        if (payload.whatsappContact)
          draft.whatsappContact = payload.whatsappContact;
        if (payload.instagramContact)
          draft.instagramContact = payload.instagramContact;
        if (payload.tiktokContact) draft.tiktokContact = payload.tiktokContact;
        if (payload.telegramContact)
          draft.telegramContact = payload.telegramContact;
        draft.numSuperping = numS;
        draft.oldestSuperping = Math.min(
          payload.superping1,
          payload.superping2
        );
        if (payload.new) draft.new = payload.new;
        if (payload.lastBestTenants)
          draft.lastBestTenants = payload.lastBestTenants;
        return;
      case SET_LOGGED:
        draft.logged = payload.logged;
        return;
      case SET_AGENDA:
        draft.entries = payload;
        return;
      case SET_NOTE:
        index = draft.entries.findIndex(
          (e) => e.timestamp === payload.timestamp
        );
        if (index !== -1) {
          draft.entries[index].note = payload.note;
        }
        return;
      case SET_NUM_SUPERPING:
        date = new Date();
        now = date.getTime();
        draft.oldestSuperping = now;
        draft.numSuperping = payload;
        return;
      case CHANGE_RESEARCH_INFO:
        draft.seeking = payload.seeking;
        draft.city = payload.city;
        draft.budget = payload.budget;
        draft.from_date = payload.from_date;
        draft.to_date = payload.to_date;
        draft.alone = payload.alone;
        draft.tenants = payload.tenants;
        draft.zone = payload.zone;
        draft.room_types = payload.room_types;
        return;
      case CHANGE_CURR_COND:
        draft.type = payload.type;
        draft.studyplace = payload.studyplace;
        draft.workplace = payload.workplace;
        return;
      case CHANGE_PERSONAL_INFO:
        draft.relationship = payload.relationship;
        draft.education = payload.education;
        draft.description = payload.description;
        draft.location = payload.location;
        return;
      case CHANGE_PERSONAL_ELEMENTS:
        draft.sport = payload.sport;
        draft.weekend = payload.weekend;
        draft.alcol = payload.alcol;
        draft.smoke = payload.smoke;
        draft.animals = payload.animals;
        return;
      case CHANGE_TENANTS_NUMBER:
        draft.tenants = state.tenants.filter((tenant) => tenant.id < payload);
        return;
      case CHANGE_IMAGE:
        if (payload.profile_image !== state.profile_image) {
          draft.profile_image = payload.profile_image;
          draft.profile_image_webp = payload.profile_image_webp;
        }
        return;
      case CHANGE_COVER_IMAGE:
        if (payload.cover_image !== state.cover_image) {
          draft.cover_image = payload.cover_image;
          draft.cover_image_webp = payload.cover_image_webp;
        }
        return;
      case CHANGE_VIDEO:
        draft.videoId = payload;
        return;
      case CHANGE_TEMPLATE:
        draft.template = payload;
        return;
      case CHANGE_SETTINGS:
        if (payload.name) draft.name = payload.name;
        if (payload.surname) draft.surname = payload.surname;
        if (payload.mail) {
          draft.mail = payload.mail;
          if (payload.active) {
            draft.active = payload.active;
          }
        }
        if (payload.subscribed) {
          draft.subscribed = payload.subscribed;
        }
        if (payload.subscribedBasic) {
          draft.subscribedBasic = payload.subscribedBasic;
        }
        if (payload.seeking) draft.seeking = payload.seeking;
        if (payload.birth) draft.birth = payload.birth;
        if (payload.gender) draft.gender = payload.gender;
        if (payload.city) draft.city = payload.city;
        if (typeof payload.terms !== "undefined") draft.terms = payload.terms;
        if (typeof payload.profiled !== "undefined")
          draft.profiled = payload.profiled;
        if (typeof payload.marketing !== "undefined")
          draft.marketing = payload.marketing;
        if (typeof payload.email_disabled !== "undefined")
          draft.email_disabled = payload.email_disabled;
        if (payload.isNotifMailConfirmed)
          draft.isNotifMailConfirmed = payload.isNotifMailConfirmed;
        if (typeof payload.whatsappContact !== "undefined")
          draft.whatsappContact = payload.whatsappContact;
        if (typeof payload.messengerContact !== "undefined")
          draft.messengerContact = payload.messengerContact;
        if (typeof payload.instagramContact !== "undefined")
          draft.instagramContact = payload.instagramContact;
        if (typeof payload.tiktokContact !== "undefined")
          draft.tiktokContact = payload.tiktokContact;
        if (typeof payload.telegramContact !== "undefined")
          draft.telegramContact = payload.telegramContact;
        return;
      case SET_HELP_SEEN:
        if (typeof payload.pwaInstallSeen !== "undefined")
          draft.pwaInstallSeen = payload.pwaInstallSeen;
        if (typeof payload.preBookingSeen !== "undefined")
          draft.preBookingSeen = payload.preBookingSeen;
        if (typeof payload.inviteHelpSeen !== "undefined")
          draft.inviteHelpSeen = payload.inviteHelpSeen;
        if (typeof payload.pingHelpSeen !== "undefined")
          draft.pingHelpSeen = payload.pingHelpSeen;
        if (typeof payload.superpingHelpSeen !== "undefined")
          draft.superpingHelpSeen = payload.superpingHelpSeen;
        if (typeof payload.shareHelpSeen !== "undefined")
          draft.shareHelpSeen = payload.shareHelpSeen;
        return;
      case SET_INVITE_HELP_SEEN:
        draft.inviteHelpSeen = payload;
        return;
      case SET_PING_HELP_SEEN:
        draft.pingHelpSeen = payload;
        return;
      case SET_SUPERPING_HELP_SEEN:
        draft.superpingHelpSeen = payload;
        return;
      case SET_SHARE_HELP_SEEN:
        draft.shareHelpSeen = payload;
        return;
      case SET_NEW_USER:
        draft.new = payload;
        return;
      case SET_TEMP_HOME:
        //console.log("SET_TEMP_HOME", payload);
        for (const [key, value] of Object.entries(payload)) {
          draft.tempHome[key] = value;
        }
        return;
      case SET_PROFILATION:
        if (typeof payload.profiled !== "undefined")
          draft.profiled = payload.profiled;
        if (typeof payload.description !== "undefined")
          draft.description = payload.description;
        if (typeof payload.location !== "undefined")
          draft.location = payload.location;
        if (typeof payload.budget !== "undefined")
          draft.budget = payload.budget;
        if (typeof payload.from_date !== "undefined")
          draft.from_date = payload.from_date;
        if (typeof payload.to_date !== "undefined")
          draft.to_date = payload.to_date;
        if (typeof payload.room_types !== "undefined")
          draft.room_types = payload.room_types;
        if (typeof payload.education !== "undefined")
          draft.education = payload.education;
        if (typeof payload.relationship !== "undefined")
          draft.relationship = payload.relationship;
        if (typeof payload.sport !== "undefined") draft.sport = payload.sport;
        if (typeof payload.weekend !== "undefined")
          draft.weekend = payload.weekend;
        if (typeof payload.alcol !== "undefined") draft.alcol = payload.alcol;
        if (typeof payload.smoke !== "undefined") draft.smoke = payload.smoke;
        if (typeof payload.animals !== "undefined")
          draft.animals = payload.animals;
        if (typeof payload.studyplace !== "undefined")
          draft.studyplace = payload.studyplace;
        if (typeof payload.workplace !== "undefined")
          draft.workplace = payload.workplace;
        if (typeof payload.interests !== "undefined")
          draft.interests = payload.interests;
        if (typeof payload.type !== "undefined") draft.type = payload.type;
        if (typeof payload.lifestyles !== "undefined")
          draft.lifestyles = payload.lifestyles;
        return;
      case RESET_REDUX:
        return initialState;
      /*case CHANGE_TENANT:
        //console.log('\nUtente '+ payload.id +'\nSesso: ' + payload.gender + '\nTipo: ' + payload.type + '\nEtà: '+ payload.age);
        if( state.tenants.findIndex(x => x.id == payload.id) != -1 ){
          return {
                  ...state;
                  tenants: state.tenants.map(tenants => tenants.id === payload.id ?
                      // transform the one with a matching id
                      { ...tenants;user_id: 0; id: payload.id;gender: payload.gender; type: payload.type; age: payload.age } :
                      // otherwise return original room
                      tenants
                  )
            }
        }
        else{
          //console.log('sono nel else');
          return Object.assign({}; state; {
            tenants: state.tenants.concat({user_id: 0;id: payload.id;gender: payload.gender; type: payload.type; age: payload.age})
          });
        }*/
      // eslint-disable-next-line no-fallthrough
    }
  });

export default userReducer;
