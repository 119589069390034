import React from "react";
import { connect } from "react-redux";
import { userSettings, userSetNew } from "../../actions/user";
import { setMissingDataModal } from "../../actions/general";
import { fetcher, isPartner } from "../utility";
import * as dateFns from "date-fns";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Link } from "react-router-dom";
import {
  TextField,
  MenuItem,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Select,
  Radio,
  RadioGroup,
  Dialog,
  DialogContent,
  adaptV4Theme,
} from "@mui/material";
import TermsSvg from "@assets/terms.svg";
import CitySvg from "@assets/city.svg";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { eventGA } from "../TrackerGA";
import { withWidthHook } from "../utility";

import { legacyParse, convertTokens } from "@date-fns/upgrade/v2";
import CitySelect from "../CitySelect";

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: "#ff7417",
      },
    },
  })
);

const mapStateToProps = (state) => {
  return {
    user: state.User,
    hasMissingModalOpened: state.General.hasMissingModalOpened,
    cities: state.General.cities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userSettings: (settings) => dispatch(userSettings(settings)),
    setMissingDataModal: () => dispatch(setMissingDataModal()),
    userSetNew: (bool) => dispatch(userSetNew(bool)),
  };
};

class MissingDataModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      convAge: true,
      convGender: true,
      convTerms: true,
      convCity: true,
      gender: "",
      city: "",
      day: null,
      month: null,
      year: null,
      terms: false,
      marketing: false,
      whereYouGo: "",
    };
  }

  componentDidMount() {
    this.checkMissingData();
    this.resetState();

    this.outer = document.createElement("div");
    this.outer.style.visibility = "hidden";
    this.outer.type = "button";
    this.outer.id = "missingData";
    this.outer.addEventListener("click", () => {
      this.toggleModal(true);
    });
    document.body.appendChild(this.outer);
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.checkMissingData();
      this.resetState();
    }
    if (this.props.cities !== prevProps.cities) {
      this.checkValidCity();
    }
  }

  componentWillUnmount() {
    this.outer.parentNode.removeChild(this.outer);
    // dialog has a side-effect if this not checked
    document.body.style.overflow = "auto";
  }

  toggleModal = (bool) => {
    this.setState({ isOpen: bool });
    if (bool) {
      window.history.pushState("popup-open", null, "");
      window.onpopstate = (e) => {
        e.preventDefault();
        window.history.pushState("popup-open", null, "");
      };
    } else {
      if (window.history.state === "popup-open") window.onpopstate = null;
      switch (this.state.whereYouGo) {
        case "search_home":
          setTimeout(() => this.props.history.push("/completa-profilo"), 300);
          break;
        case "search_tenn":
          this.props.userSetNew(false);
          setTimeout(
            () =>
              this.props.history.push("/pubblica-annuncio-stanza-appartamento"),
            300
          );
          break;
        default:
          this.props.userSetNew(false);
      }
    }
  };

  checkValidCity() {
    const { city } = this.props.user;
    const { cities } = this.props;
    if (cities && cities.length > 0) {
      const validCity = cities.map((x) => x.value).includes(city) ? city : "";
      this.setState({
        city: validCity,
      });
    }
  }

  resetState() {
    const { birth, gender, city } = this.props.user;
    const validBirth = birth && birth > 0 ? birth : null;
    this.checkValidCity();
    this.setState({
      gender: gender,
      day: parseInt(String(validBirth).substring(6, 8)),
      month: parseInt(String(validBirth).substring(4, 6)),
      year: parseInt(String(validBirth).substring(0, 4)),
    });
  }

  checkMissingData() {
    const {
      logged,
      gender,
      birth,
      terms,
      new: newUser,
      city,
    } = this.props.user;
    if (
      logged &&
      (birth <= 0 ||
        !gender ||
        gender === "" ||
        !terms ||
        !city ||
        city.length === 0) &&
      !this.props.hasMissingModalOpened
      //&& !newUser
    ) {
      setTimeout(() => this.toggleModal(true), 500);
      this.props.setMissingDataModal();
    }
  }

  submitFunction = () => {
    const { user } = this.props;
    const { pathname } = this.props.history.location;
    const { day, month, year, gender, terms, marketing, city } = this.state;

    let body = {};
    let convAge = true,
      convGender = true,
      convTerms = true,
      convCity = true;

    if (user.birth <= 0) {
      const birth =
        parseInt(year) * 10000 + parseInt(month) * 100 + parseInt(day);
      convAge = this.validAge(birth);
      body.birth = birth;
    }
    if (!user.gender || user.gender === "") {
      convGender = this.validGender(gender);
      body.gender = gender;
    }
    if (!user.terms) {
      convTerms = this.validTerms(terms);
      body.terms = terms;
      body.marketing = marketing;
    }
    if (!user.city) {
      if (pathname !== "/pubblica-annuncio-stanza-appartamento") {
        convCity = this.validCity(city);
        body.city = city;
      } else {
        body.city = user.tempHome.city;
      }
    }
    if (this.state.whereYouGo === "search_home" || isPartner()) {
      body.seeking = true;
    } else if (this.state.whereYouGo === "search_tenn") {
      body.seeking = false;
    }
    this.setState({ convAge, convGender, convTerms, convCity });
    if (convAge && convGender && convTerms && convCity) {
      fetcher({
        path: "/api/updateMissingData",
        credentials: true,
        method: "POST",
        body: body,
      })
        .then((res) => {
          if (!res.success) {
            console.log("Errore", res.error);
          } else {
            // console.log("Successo!", res);
            eventGA(
              "COMPORTAMENTO",
              "Selezione_Città",
              `User_${user._id}`,
              `${body.city}`
            );
            this.toggleModal(false);
            this.props.userSettings(body);
          }
        })
        .catch((e) => console.log("Error during Fetch:", e));
    }
  };

  validAge = (birth) => {
    if (Number.isNaN(birth)) {
      return false;
    } else {
      var ageLimit = 18;

      const today = dateFns.format(
        legacyParse(new Date()),
        convertTokens("YYYYMMDD")
      );
      const age = Math.floor((today - birth) / 10000);

      if (age < ageLimit) return false;
      else return true;
    }
  };

  validGender = (gender) => {
    if (gender === "n" || gender === "") return false;
    else return true;
  };

  validTerms = () => {
    if (this.state.terms) return true;
    else return false;
  };

  validCity = (city) => {
    if (
      this.props.cities &&
      this.props.cities.length > 0 &&
      this.props.cities.map((x) => x.value).includes(city)
    )
      return true;
    else return false;
  };

  setCity(city) {
    this.setState({ city });
  }

  checkMonthState = (event) => {
    this.setState({ month: event.target.value });
    const n_days = dateFns.getDaysInMonth(
      legacyParse(new Date(this.state.year, event.target.value - 1))
    );
    if (n_days < this.state.day) this.setState({ day: n_days });
  };

  checkYearState = (event) => {
    this.setState({ year: event.target.value });
    const n_days = dateFns.getDaysInMonth(
      legacyParse(new Date(event.target.value, this.state.month - 1))
    );
    if (n_days < this.state.day) {
      this.setState({ day: n_days });
    }
  };

  checkDayState = (event) => {
    this.setState({ day: event.target.value });
  };

  checkGenderState = (e) => {
    this.setState({ gender: e.target.value });
  };
  checkWhereYouGoState = (e) => {
    this.setState({ whereYouGo: e.target.value });
  };

  checkTermsState = (/*event*/) => {
    this.setState({ terms: !this.state.terms });
  };

  checkMarketingState = (/*event*/) => {
    this.setState({ marketing: !this.state.marketing });
  };

  showDays = (convAge, month, year) => {
    var days = [];
    let n_days = dateFns.getDaysInMonth(legacyParse(new Date(year, month - 1)));
    if (!n_days) n_days = 31;
    days.push(
      <MenuItem key="default" value={null} disabled selected hidden>
        Giorno
      </MenuItem>
    );

    for (var i = 1; i <= n_days; i++) {
      days.push(
        <MenuItem key={i} value={i}>
          {" "}
          {i}{" "}
        </MenuItem>
      );
    }
    return (
      <TextField
        key="day"
        select
        variant="outlined"
        size="small"
        fullWidth
        error={!convAge}
        autoComplete="bday-day"
        onChange={this.checkDayState}
        value={this.state.day}
        label="Giorno"
        helperText={!convAge ? "Età non valida" : null}
      >
        {days}
      </TextField>
    );
  };

  showMonths = (convAge) => {
    var months = [];
    const n_months = 12;

    months.push(
      <MenuItem key="default" value={null} disabled selected hidden>
        Mese
      </MenuItem>
    );

    for (var i = 1; i <= n_months; i++) {
      months.push(
        <MenuItem key={i} value={i}>
          {" "}
          {this.numToMonths(i)}{" "}
        </MenuItem>
      );
    }
    return (
      <TextField
        select
        variant="outlined"
        size="small"
        fullWidth
        error={!convAge}
        onChange={this.checkMonthState}
        autoComplete="bday-month"
        value={this.state.month}
        label="Mese"
      >
        {months}
      </TextField>
    );
  };

  showYears = (convAge) => {
    var years = [];
    const first_year = 1940;
    const last_year = 2005;

    years.push(
      <MenuItem key="default" value={null} disabled selected hidden>
        Anno
      </MenuItem>
    );

    for (var i = last_year; i >= first_year; i--) {
      years.push(
        <MenuItem key={i} value={i}>
          {" "}
          {i}{" "}
        </MenuItem>
      );
    }
    return (
      <TextField
        select
        variant="outlined"
        size="small"
        fullWidth
        error={!convAge}
        onChange={this.checkYearState}
        autoComplete="bday-year"
        value={this.state.year}
        label="Anno"
      >
        {years}
      </TextField>
    );
  };

  numToMonths = (index) => {
    var month_name = "";
    switch (index) {
      case 1:
        month_name = "Gennaio";
        break;
      case 2:
        month_name = "Febbraio";
        break;
      case 3:
        month_name = "Marzo";
        break;
      case 4:
        month_name = "Aprile";
        break;
      case 5:
        month_name = "Maggio";
        break;
      case 6:
        month_name = "Giugno";
        break;
      case 7:
        month_name = "Luglio";
        break;
      case 8:
        month_name = "Agosto";
        break;
      case 9:
        month_name = "Settembre";
        break;
      case 10:
        month_name = "Ottobre";
        break;
      case 11:
        month_name = "Novembre";
        break;
      case 12:
        month_name = "Dicembre";
        break;
      default:
        month_name = "";
    }
    return month_name;
  };

  render() {
    const {
      terms,
      marketing,
      convTerms,
      gender,
      convGender,
      city,
      convCity,
      whereYouGo,
      day,
      month,
      year,
      convAge,
      isOpen,
    } = this.state;
    const { pathname } = this.props.history.location;

    let fullScreen =
      !this.props.isUp && pathname !== "/pubblica-annuncio-stanza-appartamento";

    return (
      <Dialog
        fullScreen={fullScreen}
        maxWidth={"xs"}
        open={isOpen}
        onClose={() => this.toggleModal(false)}
        aria-labelledby="Gender Birth and Terms and Conditions"
        disableEscapeKeyDown={true}
        scroll="body"
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <DialogContent>
              <div className="col-12">
                {!this.props.user.terms ? <TermsSvg /> : <CitySvg />}
              </div>

              {(!this.props.user.birth ||
                this.props.user.birth <= 0 ||
                !this.props.user.gender ||
                this.props.user.gender === "") && (
                <div className="thipi-text-h5">
                  Il metodo di autenticazione scelto non ci ha fornito alcune
                  informazioni importanti.
                </div>
              )}
              {!this.props.user.active && (
                <div className="thipi-text-h4 mb-2">
                  Abbiamo inviato una mail all&apos;indirizzo{" "}
                  <span className="thipi-text-h4-b">
                    {this.props.user.mail}
                  </span>
                  . Controlla la tua casella di posta per completare la
                  registrazione.
                </div>
              )}
              {(!this.props.user.birth || this.props.user.birth <= 0) && (
                <>
                  <label
                    className="col-12 mt-2 mb-0 pt-1 thipi-text-h4-b"
                    htmlFor=""
                  >
                    Data di nascita
                  </label>
                  <div className="row pt-1">
                    <div className="py-1  col-xs-4 col-md-4 ">
                      {this.showDays(convAge, month, year)}
                    </div>
                    <div className="py-1 col-xs-4 col-md-4">
                      {this.showMonths(convAge)}
                    </div>
                    <div className="py-1 col-xs-4 col-md-4">
                      {this.showYears(convAge)}
                    </div>
                  </div>
                </>
              )}
              {(!this.props.user.gender || this.props.user.gender === "") && (
                <>
                  <label className="col-12 mt-2 mb-0 thipi-text-h4-b">
                    Genere
                  </label>
                  <div className="form-div">
                    <FormControl error={!convGender} component="fieldset">
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="gender"
                        value={gender}
                        onChange={this.checkGenderState}
                      >
                        <FormControlLabel
                          value="m"
                          control={<Radio color="primary" />}
                          label="Uomo"
                        />
                        <FormControlLabel
                          value="f"
                          control={<Radio color="primary" />}
                          label="Donna"
                        />
                      </RadioGroup>
                      <FormHelperText>
                        {!convGender ? "Seleziona una opzione" : null}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </>
              )}
              {pathname !== "/pubblica-annuncio-stanza-appartamento" && (
                <>
                  <label className="w-100 mt-2 mb-0 thipi-text-h4-b">
                    Dove cerchi?
                  </label>
                  <div className="form-div pt-2">
                    <FormControl size="small" error={!convCity} fullWidth small>
                      <CitySelect
                        fullWidth
                        shadow={false}
                        city={city}
                        small
                        setCity={(v) => this.setCity(v)}
                      />
                      <FormHelperText>
                        {!convCity ? "Seleziona una città" : null}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </>
              )}
              {pathname !== "/why-create-home" &&
                pathname !== "/pubblica-annuncio-stanza-appartamento" &&
                !isPartner() && (
                  <>
                    <label className="mt-2 mb-0 thipi-text-h4-b">
                      Cosa cerchi?
                    </label>
                    <div className="form-div mb-2">
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="whereYouGo"
                          name="whereYouGo"
                          value={whereYouGo}
                          onChange={this.checkWhereYouGoState}
                        >
                          <FormControlLabel
                            value="search_tenn"
                            control={<Radio color="primary" />}
                            label="Cerco inquilino"
                          />
                          <FormControlLabel
                            value="search_home"
                            control={<Radio color="primary" />}
                            label="Cerco casa"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </>
                )}
              {!this.props.user.terms && (
                <>
                  <div className="form-div">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={terms}
                          onChange={this.checkTermsState}
                          name="check_terms"
                          color="primary"
                        />
                      }
                      label={
                        <>
                          <span>
                            Dichiaro presa visione{" "}
                            <Link
                              to="/privacy-policy"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              privacy policy
                            </Link>{" "}
                            e{" "}
                            <Link
                              to="/terms"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              termini e condizioni
                            </Link>
                          </span>

                          {!convTerms && (
                            <div className="invalid-feedback form-isInvalid">
                              Devi accettare termini e condizioni di utilizzo
                              per proseguire
                            </div>
                          )}
                        </>
                      }
                    />
                  </div>
                  <div className="form-div">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={marketing}
                          onChange={this.checkMarketingState}
                          name="check_marketing"
                          color="primary"
                        />
                      }
                      label={
                        <span>
                          Acconsento all'accesso dei miei dati per finalità di
                          marketing
                        </span>
                      }
                    />
                  </div>
                </>
              )}
              <div className="col-12 py-3 text-center">
                <button
                  className="btn thipi-btn-color mx-3"
                  onClick={() => this.submitFunction()}
                >
                  Accetta e Continua
                </button>
              </div>
            </DialogContent>
          </ThemeProvider>
        </StyledEngineProvider>
      </Dialog>
    );
  }
}
export default compose(
  withRouter,
  withWidthHook,
  connect(mapStateToProps, mapDispatchToProps)
)(MissingDataModal);
