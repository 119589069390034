import {
  SET_HOME,
  CHANGE_NAME,
  CHANGE_ADDRESS,
  CHANGE_ADS_ADDRESS,
  CREATE_BEDROOM,
  SET_BEDROOM,
  DELETE_BEDROOM,
  ADD_BUSY_BED,
  DELETE_BUSY_BED,
  CHANGE_BATHROOMS_NUMBER,
  CHANGE_OTHERROOM,
  SET_IF_OWNER_IN_HOME,
  CHANGE_TENANTS,
  DELETE_TENANT,
  SET_ADVERTISE,
  SET_ADS,
  DELETE_ADVERTISE,
  CHANGE_SLOT,
  SET_SLOT,
  ADD_USER_IN_SLOT,
  CHANGE_SERVICE,
  SET_STEP_NUMBER,
  SET_NOTIFICATION,
  SET_NOTIFICATION_STATE,
  ADD_NOTIFICATION,
  READ_NOTIFICATION,
  ADD_PHOTO,
  ADD_PHOTO_LOADER,
  DELETE_PHOTO,
  CHANGE_PROFILE_IMAGE,
  CHANGE_PREFERENCES,
  CHANGE_DESCRIPTION,
  CHANGE_TYPE,
  CHANGE_SQUARE,
  ADD_INVITATION,
  CHANGE_WHATSAPP,
  CHANGE_MESSENGER,
  CHANGE_INSTAGRAM,
  CHANGE_TIKTOK,
  CHANGE_TELEGRAM,
  SET_NEW,
} from "../constants/action-home-types";

export const setHome = (home) => ({ type: SET_HOME, payload: home });
export const homeChangeName = (name) => ({ type: CHANGE_NAME, payload: name });
export const homeChangeAddress = (city, address, coordinates) => ({
  type: CHANGE_ADDRESS,
  payload: { city, address, coordinates },
});
export const homeCreateBedroom = (id, type) => ({
  type: CREATE_BEDROOM,
  payload: { id, type },
});
export const homeSetBedroom = (n_beds, n_busy_beds, id) => ({
  type: SET_BEDROOM,
  payload: { n_beds, n_busy_beds, id },
});
export const homeDeleteBedroom = (index) => ({
  type: DELETE_BEDROOM,
  payload: index,
});
export const homeAddBusyBed = (index) => ({
  type: ADD_BUSY_BED,
  payload: index,
});
export const homeDeleteBusyBed = (index) => ({
  type: DELETE_BUSY_BED,
  payload: index,
});
export const homeChangeBathroomsNumber = (number) => ({
  type: CHANGE_BATHROOMS_NUMBER,
  payload: number,
});
export const homeChangeOtherRooms = (other_rooms) => ({
  type: CHANGE_OTHERROOM,
  payload: other_rooms,
});
//export const homeChangeTenantsNumber = index => ({ type: CHANGE_TENANTS_NUMBER, payload: index });
export const homeSetIfOwnerInHome = (ownerInHome, ownerRoom) => ({
  type: SET_IF_OWNER_IN_HOME,
  payload: { ownerInHome, ownerRoom },
});
export const homeChangeTenants = (bedrooms) => ({
  type: CHANGE_TENANTS,
  payload: bedrooms,
});
export const homeDeleteTenant = (tenant_id) => ({
  type: DELETE_TENANT,
  payload: { tenant_id },
});
export const homeChangeSlots = (slots) => ({
  type: CHANGE_SLOT,
  payload: slots,
});
export const homeSetSlots = (slots) => ({ type: SET_SLOT, payload: slots });
export const homeChangeServices = (services) => ({
  type: CHANGE_SERVICE,
  payload: services,
});
export const homeSetStepNumber = (i) => ({ type: SET_STEP_NUMBER, payload: i });
export const homeSetAds = (advertises) => ({
  type: SET_ADS,
  payload: advertises,
});
export const homeAddUserInSlot = (slot) => ({
  type: ADD_USER_IN_SLOT,
  payload: slot,
});
export const homeSetNotifications = (notifications) => ({
  type: SET_NOTIFICATION,
  payload: notifications,
});
export const homeSetNotificationState = (id, state) => ({
  type: SET_NOTIFICATION_STATE,
  payload: { id, state },
});
export const homeAddNotification = (notification) => ({
  type: ADD_NOTIFICATION,
  payload: notification,
});
export const homeReadNotifications = () => ({ type: READ_NOTIFICATION });
export const setAdvertise = (advertise) => ({
  type: SET_ADVERTISE,
  payload: advertise,
});
export const deleteAdvertise = (index) => ({
  type: DELETE_ADVERTISE,
  payload: index,
});
export const homeChangeAdsAddress = (coordinates, zone) => ({
  type: CHANGE_ADS_ADDRESS,
  payload: { coordinates, zone },
});
export const homeAddPhoto = (photo) => ({ type: ADD_PHOTO, payload: photo });
export const homeAddPhotoLoader = (length) => ({
  type: ADD_PHOTO_LOADER,
  payload: length,
});
export const homeDeletePhoto = (id) => ({ type: DELETE_PHOTO, payload: id });
export const homeChangeProfileImage = (photo) => ({
  type: CHANGE_PROFILE_IMAGE,
  payload: photo,
});
export const homeChangePreferences = (preferences) => ({
  type: CHANGE_PREFERENCES,
  payload: preferences,
});
export const homeChangeDescription = (description) => ({
  type: CHANGE_DESCRIPTION,
  payload: description,
});
export const homeChangeType = (type) => ({
  type: CHANGE_TYPE,
  payload: type,
});
export const homeChangeSquare = (square) => ({
  type: CHANGE_SQUARE,
  payload: square,
});
export const homeAddInvitation = (ads_id, invitation_id) => ({
  type: ADD_INVITATION,
  payload: { ads_id, invitation_id },
});
export const homeChangeWhatsapp = ({ prefix, number }) => ({
  type: CHANGE_WHATSAPP,
  payload: { prefix, number },
});
export const homeChangeMessenger = ({ name, link }) => ({
  type: CHANGE_MESSENGER,
  payload: { name, link },
});
export const homeChangeInstagram = (contact) => ({
  type: CHANGE_INSTAGRAM,
  payload: contact,
});
export const homeChangeTiktok = (contact) => ({
  type: CHANGE_TIKTOK,
  payload: contact,
});
export const homeChangeTelegram = (contact) => ({
  type: CHANGE_TELEGRAM,
  payload: contact,
});
export const homeSetNew = (newHome) => ({
  type: SET_NEW,
  payload: newHome,
});
