// Navbar.js
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import "./Navbar.css";
import Logo from "@assets/brand/logo.svg";
import LogoScritta from "@assets/brand/logo-scritta.svg";
import TidHome from "@assets/brand/tidhome-logo-scritta.png";
import TidHomeLogo from "@assets/brand/tidhome-logo.svg";
import ImgChat from "@assets/img/chat.svg";
import ImgBell from "@assets/img/notification.svg";
import ImgUserLogin from "@assets/img/user_login.svg";
import ImgInfo from "@assets/img/info.svg";
//import imgUserProfile from "@assets/img/user_profile.svg";
import ImgCreateHome from "@assets/img/create_home.svg";
import ImgHome from "@assets/img/home.svg";
//import imgHomeProfile from "@assets/img/home_profile.svg";
import ImgFindHome from "@assets/img/find_home.svg";
import ImgProfile from "@assets/img/profile.svg";
import ImgFindRoommate from "@assets/img/find_roommate.svg";
import "@assets/Feelmehome.css";
import Login from "../pages/Login";
import NotificationMenu from "./NotificationMenu";
import RecoverPass from "../pages/RecoverPass";
import Signup from "../pages/Signup";
import {
  MissingDataModal,
  NotVerifiedModal,
  NotLoggedModal,
  NoHouseModal,
  NoHouseHomeModal,
  PushNotificationsModal,
  InAppBrowserModal,
  WhyRegisterModal,
  HelpHomeProfilePopover,
  WhereYouGoModal,
} from "./TutorialModals/";
import NavbarModal from "./NavbarModal";
import { connect } from "react-redux";
import { setLogged, userHelpSeen } from "../actions/user";
import { resetRedux } from "../actions/general";
import { fetcher, isPartner } from "./utility";
import { eventGA } from "./TrackerGA";
import {
  /*BottomNavigation, BottomNavigationAction,*/ Snackbar,
  Badge,
} from "@mui/material";

// eslint-disable-next-line react/display-name
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const mapStateToProps = (state) => {
  return {
    general: state.General,
    user: state.User,
    home: state.Home,
    inbox: state.Inbox,
    isAuthenticating: state.General.isAuthenticating,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLogged: (logged) => dispatch(setLogged(logged)),
    userHelpSeen: (helpSeen) => dispatch(userHelpSeen(helpSeen)),
    resetRedux: () => dispatch(resetRedux()),
  };
};

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      register: false,
      forgot: false,
      isOpen: false,
      newMsg: 0,
      deferredPrompt: null,
      buttonWhy: false,
      create: false,
      show: false,
      snackbarOpen: false,
    };
  }

  componentDidMount() {
    this.checkNewMessages();
    this.checkRef();
    this.snackbarCheck();
    window.addEventListener("beforeinstallprompt", this.deferredPromptFunction);

    this.outer = document.createElement("div");
    this.outer.style.visibility = "hidden";
    this.outer.type = "button";
    this.outer.id = "signupNavbarButton";
    this.outer.addEventListener("click", () => {
      this.registration(true);
      this.toggleModal(true);
    });

    this.outerLogin = document.createElement("div");
    this.outerLogin.style.visibility = "hidden";
    this.outerLogin.type = "button";
    this.outerLogin.id = "loginNavbarButton";
    this.outerLogin.addEventListener("click", (e) => {
      if (e.target.value) {
        this.setState({ create: true });
      }
      this.registration(false);
      this.toggleModal(true);
    });

    this.outerClose = document.createElement("div");
    this.outerClose.style.visibility = "hidden";
    this.outerClose.type = "button";
    this.outerClose.id = "closeModalButton";
    this.outerClose.addEventListener("click", () => {
      this.toggleModal(false);
    });

    this.outerShow = document.createElement("div");
    this.outerShow.style.visibility = "hidden";
    this.outerShow.type = "button";
    this.outerShow.id = "showPopoverButton";
    this.outerShow.addEventListener("click", () => {
      this.setShow();
    });

    document.body.appendChild(this.outer);
    document.body.appendChild(this.outerClose);
    document.body.appendChild(this.outerLogin);
    document.body.appendChild(this.outerShow);
  }

  snackbarCheck = () => {
    if (
      this.props.user._id > 0 &&
      !this.props.user.pwaInstallSeen &&
      this.state.deferredPrompt &&
      !this.props.isUp
    ) {
      fetcher({
        path: "/api/updateUserHelpSeen",
        credentials: true,
        method: "POST",
        body: {
          pwaInstallSeen: true,
        },
      });
      this.props.userHelpSeen({ pwaInstallSeen: true });
      setTimeout(() => this.toggleSnackbar(true), 3000);
    }
  };

  setShow = () => {
    this.setState({ show: !this.state.show });
  };

  deferredPromptFunction = (e) => {
    e.preventDefault();
    this.setState({ deferredPrompt: e }, () => this.snackbarCheck());
  };

  componentWillUnmount() {
    window.removeEventListener(
      "beforeinstallprompt",
      this.deferredPromptFunction
    );

    this.outer.parentNode.removeChild(this.outer);
    this.outerClose.parentNode.removeChild(this.outerClose);
    this.outerLogin.parentNode.removeChild(this.outerLogin);
    this.outerShow.parentNode.removeChild(this.outerShow);
  }

  componentDidUpdate(prevProps) {
    if (this.props.user._id !== prevProps.user._id) {
      this.snackbarCheck();
    }
    if (
      this.props.history.location.pathname ===
        "/pubblica-annuncio-stanza-appartamento" &&
      this.props !== prevProps
    )
      this.setState({ create: true });
    if (this.props.inbox !== prevProps.inbox) {
      this.checkNewMessages();
    }

    if (
      this.props.buttonRef !== prevProps.buttonRef &&
      (this.props.history.location.pathname === "/why-create-home" ||
        this.props.history.location.pathname === "why-user-profile")
    ) {
      this.checkRef();
    }
  }

  checkRef() {
    if (!this.props.buttonRef) return;
    this.observer = new window.IntersectionObserver(this.interSectionCallback, {
      threshold: [0, 0.1],
    });
    this.observer.observe(this.props.buttonRef);
    this.unlisten = this.props.history.listen((location /*, action*/) => {
      if (
        location.pathname !== "/why-create-home" &&
        location.pathname !== "/why-user-profile"
      ) {
        if (this.observer) this.observer.disconnect();
        this.observer = null;
        this.setState({ buttonWhy: false });
        this.unlisten();
      }
    });
  }

  interSectionCallback = (entries /*, observer*/) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio === 0) {
        this.setState({ buttonWhy: true });
      } else {
        this.setState({ buttonWhy: false });
      }
    });
  };

  checkNewMessages() {
    var convs = this.props.inbox.previews;
    var newMsg = 0;
    convs.forEach((c) => {
      if (!c.read_mark) newMsg++;
    });
    if (newMsg) this.setState({ newMsg });
  }

  toggleModal = (bool) => {
    this.setState({ isOpen: bool });
    if (bool) {
      window.history.pushState("popup-open", null, "");
      window.onpopstate = (e) => {
        e.preventDefault();
        this.setState({ isOpen: false });
        window.onpopstate = null;
      };
    } else {
      if (window.history.state === "popup-open") {
        window.onpopstate = null;
        window.history.replaceState("", null);
      }
    }
  };

  toggleSnackbar = (bool, reason) => {
    if (reason === "clickaway") return;

    this.setState({ snackbarOpen: bool });
  };

  sendGAfromWhyCH = () => {
    eventGA("COMPORTAMENTO", "Crea_Casa_Da_Why", "New_User");
  };

  sendGAfromWhyUP = () => {
    eventGA("COMPORTAMENTO", "Lasciati_Invitare_Da_Why", "New_User");
  };

  switch = () => {
    this.setState((prevState) => ({
      register: !prevState.register,
      forgot: false,
    }));
  };

  registration = (bool) => {
    this.setState({ register: bool, forgot: false });
  };

  forgot = () => {
    if (!this.state.forgot) {
      this.setState({ forgot: true });
    } else if (!this.state.register) {
      this.setState({ register: false });
      this.setState({ forgot: false });
    } else {
      this.setState({ register: true });
      this.setState({ forgot: false });
    }
  };

  close = () => {
    setTimeout(this.setState({ register: false }), 150);
    setTimeout(this.setState({ forgot: false }), 150);
    this.toggleModal(false);
  };

  logout = () => {
    fetcher({ path: "/api/logOut", credentials: true, redirect: "follow" })
      .then((/*res*/) => {
        this.setState({ logged: false });
        this.props.setLogged(false);
        //console.log("in logout", this.props.user);
        if (this.props.user.social === "facebook" && window.FB) {
          window.FB.getLoginStatus((response) => {
            if (response.status === "connected") {
              window.FB.logout((/*response*/) => {
                window.location.reload();
              });
            } else {
              window.location.reload();
            }
          });
        }
        //  else if (
        //   this.props.user.social === "google" &&
        //   window.gapi &&
        //   window.gapi.auth2
        // ) {
        //   var auth2 = window.gapi.auth2.getAuthInstance();
        //   if (auth2) {
        //     auth2.signOut().then(() => {
        //       window.location.reload();
        //     });
        //   } else {
        //     window.location.reload();
        //   }
        // }
        else {
          //this.props.resetRedux();
          window.location.reload();
        }
      })
      .catch((e) => console.log("Error during Fetch:", e));
  };

  renderIconNavbar = (
    isAuthenticating,
    home_id,
    selected,
    logged,
    user_seeking,
    city
  ) => {
    return (
      <nav className="navbar navbar-light navbar-expand-lg navbar-expand-xl navbar-expand-md d-flex d-md-none">
        <Link
          to="/"
          className="nounderline col text-center pl-0"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          <div className="navbar-text d-inline-block d-md-none">
            <div className="row">
              <div className="col-12 nopadding text-center">
                {!isPartner() ? (
                  <Logo className="svg-thipibo-color" height="20" width="20" />
                ) : (
                  <TidHomeLogo height="30" width="30" />
                )}
              </div>
            </div>

            {!isPartner() && (
              <div className="row">
                <div
                  className={
                    "col-12 text-center nopadding thipi-text-h8-b " +
                    (selected === "HOM" ? " text-thipibo-color " : "")
                  }
                >
                  Home
                </div>
              </div>
            )}
          </div>
        </Link>
        {!isAuthenticating && (
          <React.Fragment>
            {((logged && !user_seeking) || isPartner()) && (
              <Link
                className="nounderline col text-center"
                to={!isPartner() ? "/cerca-inquilino/" + city : "/"}
              >
                <div className="navbar-text d-inline-block d-md-none">
                  <div className="row">
                    <div className="col-12 nopadding text-center">
                      <ImgFindRoommate
                        className={
                          (
                            !isPartner()
                              ? selected === "SRT"
                              : selected === "HOM"
                          )
                            ? "svg-thipibo-color stroke-thipibo-color"
                            : "svg-thipibo-gray-color stroke-thipibo-thipibo-color"
                        }
                        width="20"
                        height="20"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={
                        "col-12 text-center nopadding thipi-text-h8-b " +
                        ((
                          !isPartner() ? selected === "SRT" : selected === "HOM"
                        )
                          ? " text-thipibo-color "
                          : "")
                      }
                    >
                      Inquilini
                    </div>
                  </div>
                </div>
              </Link>
            )}

            {logged && (user_seeking || isPartner()) && (
              <Link className="nounderline col text-center" to="/user-profile">
                <div className="navbar-text d-inline-block d-md-none">
                  <div className="row">
                    <div className="col-12 nopadding text-center">
                      <ImgProfile
                        className={
                          selected === "UPR"
                            ? "svg-thipibo-color"
                            : "svg-thipibo-gray-color"
                        }
                        width="20"
                        height="20"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={
                        "col-12 text-center nopadding thipi-text-h8-b " +
                        (selected === "UPR" ? " text-thipibo-color " : "")
                      }
                    >
                      Profilo
                    </div>
                  </div>
                </div>
              </Link>
            )}

            {!logged && (
              <div
                className=" col text-center navbar-text d-inline-block nounderline d-md-none"
                onClick={() => {
                  this.setState({ create: false });
                  this.registration(false);
                  this.toggleModal(true);
                }}
              >
                <div className="row">
                  <div className="col-12 nopadding text-center">
                    <ImgUserLogin
                      className={
                        selected === "LOG"
                          ? "svg-thipibo-color"
                          : "svg-thipibo-gray-color"
                      }
                      width="20"
                      height="20"
                      alt=""
                    />
                  </div>
                </div>
                <div className="row">
                  <div
                    className={
                      "col-12 text-center nopadding thipi-text-h8-b " +
                      (selected === "LOG" ? " text-thipibo-color " : "")
                    }
                  >
                    Accedi
                  </div>
                </div>
              </div>
            )}

            {(!logged || (home_id == -1 && !user_seeking)) && !isPartner() && (
              <Link
                className="nounderline  col text-center"
                to={
                  selected !== "WCH" && selected !== "CRH"
                    ? "/why-create-home"
                    : "/pubblica-annuncio-stanza-appartamento"
                }
              >
                <div className="navbar-text d-inline-block d-md-none">
                  <div className="row">
                    <div className="col-12 nopadding text-center">
                      <ImgCreateHome
                        className={
                          selected === "CRH" || selected === "WCH"
                            ? "svg-thipibo-color"
                            : "svg-thipibo-gray-color"
                        }
                        width="20"
                        height="20"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={
                        "col-12 text-center nopadding thipi-text-h8-b " +
                        (selected === "CRH" || selected === "WCH"
                          ? " text-thipibo-color "
                          : "")
                      }
                    >
                      Pubblica
                    </div>
                  </div>
                </div>
              </Link>
            )}

            {(!logged || user_seeking) && !isPartner() && (
              <Link
                className="nounderline col text-center"
                to={"/affitto/" + city}
              >
                <div className="navbar-text d-inline-block d-md-none">
                  <div className="row">
                    <div className="col-12 nopadding text-center">
                      <ImgFindHome
                        className={
                          selected === "SRA"
                            ? "svg-thipibo-color"
                            : "svg-thipibo-gray-color"
                        }
                        width="20"
                        height="20"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={
                        "col-12 text-center nopadding thipi-text-h8-b " +
                        (selected === "SRA" ? " text-thipibo-color " : "")
                      }
                    >
                      Cerca
                    </div>
                  </div>
                </div>
              </Link>
            )}

            {home_id !== -1 && !user_seeking && (
              <div className=" col text-center">
                <HelpHomeProfilePopover
                  className="d-md-none"
                  show={this.state.show}
                  setShow={() => this.setShow()}
                >
                  <Link className="nounderline" to="/home-profile">
                    <div className="navbar-text d-inline-block d-md-none">
                      <div className="row">
                        <div className="col-12 nopadding text-center">
                          <ImgHome
                            className={
                              selected === "HPR"
                                ? "svg-thipibo-color"
                                : "svg-thipibo-gray-color"
                            }
                            width="20"
                            height="20"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className={
                            "col-12 text-center nopadding thipi-text-h8-b " +
                            (selected === "HPR" ? " text-thipibo-color " : "")
                          }
                        >
                          Annunci
                        </div>
                      </div>
                    </div>
                  </Link>
                </HelpHomeProfilePopover>
              </div>
            )}

            {!logged && !isPartner() && (
              <Link className="nounderline col text-center" to="/come-funziona">
                <div
                  className={
                    "navbar-text " +
                    (selected === "HIW" ? " navbar-text-active " : "")
                  }
                >
                  <div className="row">
                    <div className="col-12 nopadding text-center">
                      <ImgInfo
                        className={
                          selected === "HIW"
                            ? "svg-thipibo-color"
                            : "svg-thipibo-gray-color"
                        }
                        width="20"
                        height="20"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={
                        "col-12 text-center nopadding thipi-text-h8-b " +
                        (selected === "HIW" ? " text-thipibo-color " : "")
                      }
                    >
                      Info
                    </div>
                  </div>
                </div>
              </Link>
            )}

            {logged && (
              <Link className="nounderline col text-center" to="/inbox">
                <div className="navbar-text d-inline-block d-md-none">
                  <div className="row">
                    <div className="col-12 nopadding text-center">
                      <Badge
                        badgeContent={this.state.newMsg}
                        color="primary"
                        max={9}
                      >
                        <ImgChat
                          className={
                            selected === "MSG"
                              ? "svg-thipibo-color"
                              : "svg-thipibo-gray-color"
                          }
                          width="20"
                          height="20"
                          alt=""
                        />
                      </Badge>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={
                        "col-12 text-center nopadding thipi-text-h8-b " +
                        (selected === "MSG" ? " text-thipibo-color " : "")
                      }
                    >
                      Chat
                    </div>
                  </div>
                </div>
              </Link>
            )}
            {logged && !isPartner() && (
              <Link
                className="nav-item nounderline col text-center"
                to={"/notifications"}
              >
                <div className="navbar-text d-inline-block d-md-none">
                  <div className="row">
                    <div className="col-12 nopadding text-center">
                      <Badge
                        badgeContent={this.props.general.nread}
                        color="primary"
                        max={5}
                      >
                        <ImgBell
                          className={
                            selected === "NTF"
                              ? "svg-thipibo-color"
                              : "svg-thipibo-gray-color"
                          }
                          width="20"
                          height="20"
                          alt=""
                        />
                      </Badge>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={
                        "col-12 text-center nopadding thipi-text-h8-b " +
                        (selected === "NTF" ? " text-thipibo-color " : "")
                      }
                    >
                      Notifiche
                    </div>
                  </div>
                </div>
              </Link>
            )}

            <NavbarModal
              selected={selected}
              logged={logged}
              home_id={home_id}
              logout={this.logout}
              registration={this.registration}
              deferredPrompt={this.state.deferredPrompt}
              city={city}
            />

            <div className="col text-center pr-0">
              <button
                className="navbar-toggler nopadding"
                type="button"
                onClick={() =>
                  document.getElementById("NavbarModalButton").click()
                }
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon nopadding"></span>
              </button>
            </div>
          </React.Fragment>
        )}
      </nav>
    );
  };

  renderTextNavbar = (
    isAuthenticating,
    home_id,
    selected,
    logged,
    profile_image,
    profile_image_webp,
    city
  ) => {
    return (
      <nav className="navbar navbar-light navbar-expand-lg navbar-expand-xl navbar-expand-md d-none d-md-flex px-5">
        <Link
          to="/"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          <div>
            {!isPartner() ? (
              <LogoScritta
                className="svg-thipibo-color"
                height="40"
                width="109"
              />
            ) : (
              <img src={TidHome} alt="TidHome" height="auto" width="109" />
            )}
          </div>
        </Link>
        {!isAuthenticating && (
          <React.Fragment>
            <ul className="navbar-nav ml-auto">
              {!isPartner() && (
                <li
                  className={
                    "nav-item nav-item-text" +
                    (selected === "HIW" ? " nav-item-text-active " : "")
                  }
                >
                  <Link to="/come-funziona">
                    <div
                      className={
                        "btn thipi-btn-color-light-rounded-hover " +
                        (selected === "HIW" ? " navbar-text-active " : "")
                      }
                    >
                      Come funziona
                    </div>
                  </Link>
                </li>
              )}

              {!isPartner() && (
                <li
                  className={
                    "nav-item nav-item-text " +
                    (selected === "SRA" ? " nav-item-text-active " : "")
                  }
                >
                  <Link to={"/affitto/" + city}>
                    <div
                      className={
                        "btn thipi-btn-color-light-rounded-hover " +
                        (selected === "SRA" ? " navbar-text-active " : "")
                      }
                    >
                      Trova casa
                    </div>
                  </Link>
                </li>
              )}

              <li
                className={
                  "nav-item nav-item-text " +
                  (selected === (!isPartner() ? "SRT" : "HOM")
                    ? " nav-item-text-active "
                    : "")
                }
              >
                <Link to={!isPartner() ? "/cerca-inquilino/" + city : "/"}>
                  <div
                    className={
                      "btn thipi-btn-color-light-rounded-hover " +
                      (selected === (!isPartner() ? "SRT" : "HOM")
                        ? " navbar-text-active "
                        : "")
                    }
                  >
                    Trova coinquilino
                  </div>
                </Link>
              </li>

              {/* {logged && (
                <li
                  className={
                    "nav-item nav-item-text " +
                    (selected === "AGN" ? " nav-item-text-active " : "")
                  }
                >
                  <Link to="/agenda">
                    <div
                      className={
                        "btn thipi-btn-color-light-rounded-hover " +
                        (selected === "AGN" ? " navbar-text-active " : "")
                      }
                    >
                      Agenda
                    </div>
                  </Link>
                </li>
              )} */}

              {!isPartner() && home_id != -1 && selected !== "WUP" && (
                <li
                  className={
                    "nav-item nav-item-text " +
                    (selected === "HPR" ? " nav-item-text-active " : "")
                  }
                >
                  <HelpHomeProfilePopover
                    className="d-none d-md-block"
                    show={this.state.show}
                    setShow={() => this.setShow()}
                  >
                    <Link to="/home-profile">
                      <div
                        className={
                          "btn thipi-btn-color-light-rounded-hover " +
                          (selected === "HPR" ? " navbar-text-active " : "")
                        }
                      >
                        Gestisci annunci
                      </div>
                      {/*<button className="btn btn-myoutline  ml-1" type="button">My Home</button>*/}
                    </Link>
                  </HelpHomeProfilePopover>
                </li>
              )}

              {/* {selected === "WUP" && (
                <li className={"nav-item nav-item-text my-auto "}>
                  {logged ? (
                    <Link to="/user-profile?tab=resElem">
                      {!this.state.buttonWhy ? (
                        <div
                          onClick={this.sendGAfromWhyUP}
                          className={"navbar-text "}
                        >
                          Completa il profilo
                        </div>
                      ) : (
                        <button
                          className="btn btn-sm thipi-btn-color-rounded "
                          onClick={this.sendGAfromWhyUP}
                        >
                          Completa il profilo
                        </button>
                      )}
                    </Link>
                  ) : (
                    <>
                      {!this.state.buttonWhy ? (
                        <div
                          className={"navbar-text cursor-pointer"}
                          onClick={() => {
                            this.sendGAfromWhyUP();
                            setTimeout(
                              () =>
                                document
                                  .getElementById("loginNavbarButton")
                                  .click(),
                              300
                            );
                          }}
                        >
                          Completa il profilo
                        </div>
                      ) : (
                        <button
                          className="btn btn-sm thipi-btn-color-rounded "
                          onClick={() => {
                            this.sendGAfromWhyUP();
                            setTimeout(
                              () =>
                                document
                                  .getElementById("loginNavbarButton")
                                  .click(),
                              300
                            );
                          }}
                        >
                          Completa il profilo
                        </button>
                      )}
                    </>
                  )}
                </li>
              )} */}

              {!logged && (
                <li
                  className={
                    "nav-item nav-item-text " +
                    (selected === "LOG" ? " nav-item-text-active " : "")
                  }
                >
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      this.setState({ create: false });
                      this.registration(false);
                      this.toggleModal(true);
                    }}
                  >
                    <div
                      className={
                        "btn thipi-btn-color-light-rounded-hover " +
                        (selected === "LOG" ? " navbar-text-active " : "")
                      }
                    >
                      Accedi
                    </div>
                  </div>
                </li>
              )}

              {!isPartner() && home_id == -1 && selected !== "WUP" && (
                <li
                  className={
                    "nav-item nav-item-text "
                    // + (selected === "CRH" ? " nav-item-text-active " : "")
                    // + (this.state.buttonWhy ? " my-auto " : "")
                  }
                >
                  <Link
                    to={
                      selected !== "WCH" && selected !== "CRH"
                        ? "/why-create-home"
                        : "/pubblica-annuncio-stanza-appartamento"
                    }
                  >
                    {/* {!this.state.buttonWhy ? ( */}
                    <div
                      className={
                        "btn thipi-btn-color-light-rounded "
                        // + (selected === "CRH" ? " navbar-text-active " : "")
                      }
                    >
                      Pubblica annuncio {selected !== "CRH" && "➜"}
                    </div>
                    {/* ) : (
                      <button
                        className="btn btn-sm thipi-btn-color-rounded "
                        onClick={this.sendGAfromWhyCH}
                      >
                        Pubblica annuncio
                      </button>
                    )} */}
                  </Link>
                </li>
              )}

              {/* _______________Tasto NOTIFICA_______________________*/}
              {logged && (
                <Link
                  className="nounderline nav-item nav-item-text"
                  to={
                    this.props.inbox.previews[0]
                      ? "/messages?&a=" + this.props.inbox.previews[0].id
                      : "/messages"
                  }
                >
                  <div className="navbar-text d-md-inline-block d-none">
                    <Badge
                      badgeContent={this.state.newMsg}
                      color="primary"
                      max={9}
                    >
                      <ImgChat
                        className={
                          selected === "MSG"
                            ? "svg-thipibo-color"
                            : "svg-thipibo-gray-color"
                        }
                        width="25"
                        height="25"
                        alt=""
                      />
                    </Badge>
                  </div>
                </Link>
              )}

              {logged && !isPartner() && (
                <NotificationMenu
                  selected={selected === "NTF"}
                  view="desktop"
                />
              )}

              {logged && (
                <React.Fragment>
                  <li className="nav-item d-none d-md-block">
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdownMenuButton"
                      >
                        <picture>
                          {profile_image_webp &&
                            profile_image_webp.search("large") && (
                              <source
                                type="image/webp"
                                srcSet={profile_image_webp.replace(
                                  "large",
                                  "small"
                                )}
                              />
                            )}
                          {profile_image && profile_image.search("large") && (
                            <source
                              type="image/jpeg"
                              srcSet={profile_image.replace("large", "small")}
                            />
                          )}
                          {profile_image_webp && profile_image_webp != "" && (
                            <>
                              <source
                                type="image/webp"
                                srcSet={profile_image_webp}
                              />
                              <source
                                type="image/jpeg"
                                srcSet={profile_image}
                              />
                            </>
                          )}
                          <img
                            src={profile_image}
                            className="profile-navbar-img navbar-img-link avatar img-circle img-responsive"
                            alt="avatar"
                          />
                        </picture>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        id="dropdownMenu "
                        //className="dropdown-menu dropdown-menu-user"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <Link className="nounderline" to="/user-profile">
                          <Dropdown.Item
                            as="div"
                            className="user-dropdown-item"
                            href="/user-profile"
                          >
                            Il mio profilo
                          </Dropdown.Item>
                        </Link>
                        <Link className="nounderline" to="/settings">
                          <Dropdown.Item
                            as="div"
                            className="user-dropdown-item"
                            href="/settings"
                          >
                            Impostazioni
                          </Dropdown.Item>
                        </Link>
                        <Dropdown.Item
                          as="div"
                          className="cursor-pointer user-dropdown-item"
                          onClick={() => {
                            this.logout();
                          }}
                        >
                          Esci
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </React.Fragment>
              )}
            </ul>
          </React.Fragment>
        )}
      </nav>
    );
  };

  render() {
    const {
      //first,
      logged,
      city,
      //active,
      //name,
      //surname,
      seeking,
      profile_image,
      profile_image_webp,
    } = this.props.user;
    const { cities } = this.props.general;
    let cityElem = cities?.find((x) => x.value === city);
    if (!cityElem) {
      cityElem = cities?.[0];
    }
    // console.log("NAVIGATION",this.props.history.location.pathname)
    const { /*hash,*/ pathname } = this.props.history.location;
    const { isAuthenticating } = this.props;
    const home_id = this.props.home._id;
    const { isOpen, register, forgot } = this.state;

    let selected = "";
    if (isOpen) {
      selected = "LOG";
    } else if (pathname === "/notifications") {
      //Notification
      selected = "NTF";
    } else if (pathname === "/inbox" || pathname === "/messages") {
      selected = "MSG";
    } else if (pathname === "/come-funziona") {
      selected = "HIW";
    } else if (pathname === "/") {
      selected = "HOM";
    } else if (pathname.includes("/affitto/")) {
      selected = "SRA";
    } else if (pathname.includes("/cerca-inquilino/")) {
      selected = "SRT";
      // } else if (pathname === "/agenda") {
      //   selected = "AGN";
    } else if (pathname === "/home-profile") {
      selected = "HPR";
    } else if (pathname === "/pubblica-annuncio-stanza-appartamento") {
      selected = "CRH";
    } else if (pathname === "/why-create-home") {
      selected = "WCH";
    } else if (pathname === "/user-profile") {
      selected = "UPR";
    } else if (pathname === "/settings") {
      selected = "STT";
    }

    return (
      <div id="navbar" className="row-nav">
        {this.renderTextNavbar(
          isAuthenticating,
          home_id,
          selected,
          logged,
          profile_image,
          profile_image_webp,
          cityElem?.value
        )}
        {this.renderIconNavbar(
          isAuthenticating,
          home_id,
          selected,
          logged,
          seeking,
          cityElem?.value
        )}

        <MissingDataModal />
        <NotVerifiedModal />
        <NotLoggedModal />
        <NoHouseModal />
        <NoHouseHomeModal />
        <PushNotificationsModal />
        <InAppBrowserModal />
        <WhyRegisterModal />
        <WhereYouGoModal />

        <div className="snackbar-container">
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={this.state.snackbarOpen}
            //autoHideDuration={7000}
            onClose={(e, r) => this.toggleSnackbar(false, r)}
            message={
              <div>
                <div className="snackbar-title">Installa</div>
                Usa velocemente la piattaforma senza passare dal sito web e non
                perdere nessuna richiesta
              </div>
            }
            action={
              <React.Fragment>
                <button
                  type="button"
                  className="btn btn-sm thipi-btn-color-rounded full-width my-1"
                  onClick={() => {
                    this.state.deferredPrompt.prompt();
                    this.toggleSnackbar(false);
                    eventGA(
                      "COMPORTAMENTO",
                      "PWA_fromSnackbar",
                      "User_" + this.props.user._id,
                      this.props.user.city
                    );
                  }}
                >
                  Installa
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-myoutline-rounded px-3 my-1"
                  onClick={() => {
                    this.toggleSnackbar(false);
                  }}
                >
                  Continua
                </button>
              </React.Fragment>
            }
          />
          {/*<Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={this.state.snackbarOpen}
              //autoHideDuration={15000}
              onClose={(e, r) => this.toggleSnackbar(false, r)}
            >
              <Alert
                icon={false}
                severity="success"
                onClose={(e) => this.toggleSnackbar(false)}
                action={
                  <React.Fragment>
                    <button
                      type="button"
                      className="btn no-btn text-white px-3 py-2"
                      onClick={() => {
                        this.state.deferredPrompt.prompt();
                        this.toggleSnackbar(false);
                        eventGA(
                          "COMPORTAMENTO",
                          "PWA_fromSnackbar",
                          "User_" +
                            this.props.user._id +
                            "_" +
                            this.props.user.city
                        );
                      }}
                    >
                      Installa
                    </button>

                    <button
                      type="button"
                      className="btn no-btn text-white px-3 py-2"
                      onClick={() => {
                        this.toggleSnackbar(false);
                      }}
                    >
                      ×
                    </button>
                  </React.Fragment>
                }
              >
                <AlertTitle>Installa</AlertTitle>
                Ritorna velocemente nel nostro portale senza passare dal sito
                web e non perderti nessuna richiesta
                {/*<button
                  type="button"
                  className="modal-close-top-right close text-white"
                  onClick={() => {
                    this.toggleSnackbar(false);
                  }}
                >
                  ×
                </button>
              </Alert>
            </Snackbar>*/}
        </div>

        <Modal
          show={this.state.isOpen}
          onHide={() => this.toggleModal(false)}
          dialogClassName="modal-log-dialog"
          centered
        >
          <button
            type="button"
            className="close modal-close-login position-absolute modal-close-top-right"
            id="closing_modal"
            aria-hidden="true"
            onClick={() => {
              this.close();
            }}
          >
            ×
          </button>

          <div className="modal-body">
            {forgot ? (
              <>
                <RecoverPass />
                <div className="thipi-text-h4">
                  <span
                    onClick={() => {
                      this.forgot();
                    }}
                  >
                    <div className="modal-link mt-3">Annulla</div>
                  </span>
                </div>
              </>
            ) : register ? (
              <Signup history={this.props.history}>
                <div className="thipi-text-h4">
                  Hai già un account?
                  <span
                    onClick={() => {
                      this.switch();
                    }}
                  >
                    <div className="modal-link inline ml-2">Accedi</div>
                  </span>
                </div>
                <Login
                  history={this.props.history}
                  create={this.state.create}
                  inSignup={true}
                />
              </Signup>
            ) : (
              <>
                <Login history={this.props.history} create={this.state.create}>
                  <div className="thipi-text-h4">
                    Non hai un account?
                    <span
                      onClick={() => {
                        this.switch();
                      }}
                    >
                      <div className="modal-link inline ml-2">Iscriviti</div>
                    </span>
                  </div>
                </Login>
                <span
                  onClick={() => {
                    this.forgot();
                  }}
                >
                  <div className="modal-link mt-3">
                    {!forgot ? "Password dimenticata?" : ""}
                  </div>
                </span>
              </>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Navbar);
