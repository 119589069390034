import {
  SET_HOME,
  CHANGE_NAME,
  CHANGE_ADDRESS,
  CHANGE_TYPE,
  CHANGE_ADS_ADDRESS,
  CREATE_BEDROOM,
  SET_BEDROOM,
  DELETE_BEDROOM,
  ADD_BUSY_BED,
  DELETE_BUSY_BED,
  CHANGE_BATHROOMS_NUMBER,
  CHANGE_OTHERROOM,
  SET_IF_OWNER_IN_HOME,
  CHANGE_TENANTS,
  DELETE_TENANT,
  SET_ADVERTISE,
  DELETE_ADVERTISE,
  SET_ADS,
  CHANGE_SLOT,
  SET_SLOT,
  ADD_USER_IN_SLOT,
  CHANGE_SERVICE,
  SET_STEP_NUMBER,
  SET_NOTIFICATION,
  SET_NOTIFICATION_STATE,
  ADD_NOTIFICATION,
  READ_NOTIFICATION,
  ADD_PHOTO,
  ADD_PHOTO_LOADER,
  DELETE_PHOTO,
  CHANGE_PROFILE_IMAGE,
  CHANGE_PREFERENCES,
  CHANGE_DESCRIPTION,
  CHANGE_SQUARE,
  ADD_INVITATION,
  CHANGE_WHATSAPP,
  CHANGE_MESSENGER,
  CHANGE_TIKTOK,
  CHANGE_INSTAGRAM,
  CHANGE_TELEGRAM,
  SET_NEW,
} from "../constants/action-home-types";
import { RESET_REDUX } from "../constants/action-general-types";
import produce from "immer";

const initialState = {
  _id: -1,
  name: "",
  address: "",
  description: "",
  coordinates: {},
  city: "",
  profile_image: null,
  owner_id: 0,
  slots: [],
  bedrooms: [],
  tenants: [],
  n_bathrooms: 0,
  otherrooms: [],
  preferences: [],
  services: [],
  advertises: [],
  step_number: 0,
  signup_date: 0,
  notifications: [],
  photo_array: [],
  new: false,
  ownerInHome: null,
  ownerRoom: null,
  messengerContact: { link: null, name: null },
  whatsappContact: { prefix: null, number: null },
  instagramContact: null,
  tiktokContact: null,
  telegramContact: null,
  type: "", //questo differenzia tra "Intero Appartamento"- APT o "Singoli Annunci"- RM
  square: 0,
};

const homeReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    let tenants, index, indexPending, not_index;
    // eslint-disable-next-line default-case
    switch (type) {
      case SET_HOME:
        draft._id = payload._id;
        draft.name = payload.name;
        draft.address = payload.address;
        draft.description = payload.description;
        draft.coordinates = payload.coordinates;
        draft.city = payload.city;
        draft.profile_image = payload.profile_image;
        draft.owner_id = payload.owner_id;
        if (payload.slots) draft.slots = payload.slots;
        if (payload.advertises) draft.advertises = payload.advertises;
        draft.bedrooms = payload.bedrooms;
        draft.tenants = payload.tenants;
        draft.n_bathrooms = payload.n_bathrooms;
        draft.otherrooms = payload.otherrooms;
        draft.services = payload.services;
        draft.preferences = payload.preferences;
        draft.signup_date = payload.signUpDate;
        draft.photo_array = payload.photo_array;
        draft.messengerContact = payload.messengerContact;
        draft.whatsappContact = payload.whatsappContact;
        draft.instagramContact = payload.instagramContact;
        draft.tiktokContact = payload.tiktokContact;
        draft.telegramContact = payload.telegramContact;
        draft.type = payload.type;
        draft.square = payload.square;
        draft.new = payload.new;
        return;
      case CHANGE_NAME:
        draft.name = payload;
        return;
      case CHANGE_ADDRESS:
        draft.city = payload.city;
        draft.address = payload.address;
        draft.coordinates = payload.coordinates;
        return;
      case CHANGE_TYPE:
        draft.type = payload;
        if (payload === "APT") {
          draft.bedrooms.forEach((bedroom, i) => {
            draft.bedrooms[i].n_busy_beds = 0;
            draft.bedrooms[i].tenants = [];
          });
        }
        return;
      case CHANGE_DESCRIPTION:
        draft.description = payload;
        return;
      case CHANGE_SQUARE:
        draft.square = payload;
        return;
      case CREATE_BEDROOM:
        let n_busy_beds = payload.type !== "APT" ? 1 : 0;
        tenants =
          payload.type !== "APT"
            ? [{ id: 0, gender: "n", type: "tenant", age: "" }]
            : [];
        draft.bedrooms.push({
          id: payload.id,
          n_beds: 1,
          n_busy_beds,
          tenants,
        });
        return;
      case SET_BEDROOM:
        //console.log('\nStanza '+ payload.id+'\nLetti: ' + payload.n_beds + '\nLetti Occupati: ' + payload.n_busy_beds)
        tenants = [];
        for (var i = 0; i < payload.n_busy_beds && i < payload.n_beds; i++) {
          tenants.push({ id: i, gender: "n", type: "tenant", age: "" });
        }
        index = state.bedrooms.findIndex((x) => x.id == payload.id);
        draft.bedrooms[index].n_beds = payload.n_beds;
        draft.bedrooms[index].n_busy_beds =
          payload.n_busy_beds > payload.n_beds
            ? payload.n_beds
            : payload.n_busy_beds > 0
            ? payload.n_busy_beds
            : 0;
        draft.bedrooms[index].tenants = tenants;
        return;
      case DELETE_BEDROOM:
        //console.log(payload);
        draft.bedrooms = state.bedrooms.slice(0, payload);
        return;
      case ADD_BUSY_BED:
        index = payload;
        if (
          state.bedrooms[index].n_busy_beds + 1 <=
          state.bedrooms[index].n_beds
        ) {
          draft.bedrooms[index].n_busy_beds++;
          draft.bedrooms[index].tenants.push({
            id: i,
            gender: "n",
            type: "tenant",
            age: "",
          });
        }
        return;
      case DELETE_BUSY_BED:
        index = payload;
        if (state.bedrooms[index].n_busy_beds - 1 >= 0) {
          draft.bedrooms[index].n_busy_beds--;
          draft.bedrooms[index].tenants.pop();
        }
        return;
      case CHANGE_BATHROOMS_NUMBER:
        draft.n_bathrooms = payload;
        return;
      case CHANGE_OTHERROOM:
        draft.otherrooms = [];
        payload.forEach((otherroom) => {
          if (otherroom.exist) {
            draft.otherrooms.push(otherroom.value);
          }
        });
        return;
      case SET_IF_OWNER_IN_HOME:
        //console.log("payload", payload);
        draft.ownerInHome = payload.ownerInHome;
        draft.ownerRoom = payload.ownerRoom;
        return;
      case CHANGE_TENANTS:
        draft.tenants = payload;
        return;
      case DELETE_TENANT:
        draft.tenants.forEach((tenant, i) => {
          if (tenant.id == payload.tenant_id) {
            draft.tenants.splice(i, 1);
          }
        });
        return;
      case CHANGE_SLOT:
        //console.log("log reducer ",payload)
        draft.slots = payload;
        return;
      case SET_SLOT:
        draft.slots = payload.map((element) => {
          return {
            slot: new Date(
              element.year,
              element.month - 1,
              element.day + 1,
              element.hour,
              element.minute
            ),
            num: element.numInterview,
            id_users_pending: element.id_users_pending,
            id_users_accepted: element.id_users_accepted,
            id_users_refused: element.id_users_refused,
          };
        });
        return;
      case ADD_USER_IN_SLOT:
        var slot_index = draft.slots.findIndex(
          (element) =>
            element.slot.getHours() == payload.hour &&
            element.slot.getMinutes() == payload.minute &&
            element.slot.getDate() == payload.day + 1 &&
            element.slot.getMonth() == payload.month - 1
        );
        //console.log("in add", payload, slot_index);
        if (slot_index != -1) {
          //console.log("in add if", payload);
          if (payload.state == "accepted") {
            draft.slots[slot_index].id_users_accepted.push(payload.id);
            indexPending = draft.slots[slot_index].id_users_pending.indexOf(
              payload.id
            );
            if (indexPending > -1) {
              draft.slots[slot_index].id_users_pending.splice(indexPending, 1);
            }
          }
          if (payload.state == "refused") {
            draft.slots[slot_index].id_users_refused.push(payload.id);
            indexPending = draft.slots[slot_index].id_users_pending.indexOf(
              payload.id
            );
            if (indexPending > -1) {
              draft.slots[slot_index].id_users_pending.splice(indexPending, 1);
            }
          }
          if (payload.state == "pending")
            draft.slots[slot_index].id_users_pending.push(payload.id);
        }

        return;
      case CHANGE_SERVICE:
        draft.services = [];
        payload.forEach((service) => {
          if (service.exist) {
            draft.services.push(service.value);
          }
        });
        return;
      case SET_STEP_NUMBER:
        draft.step_number = payload;
        return;
      case ADD_INVITATION:
        index = state.advertises.findIndex(
          (advertise) => advertise._id == payload.ads_id
        );
        draft.advertises[index].invitations.push(payload.invitation_id);
        return;
      case SET_ADVERTISE:
        //console.log("SET ADVERTISE");
        index = state.advertises.findIndex(
          (advertise) => advertise._id == payload._id
        );
        if (index == -1) {
          //console.log("SET ADV NEW!");
          const length = draft.advertises.push({});
          index = length - 1;
          draft.advertises[index]._id = payload._id;
          draft.advertises[index].home_id = state._id;
        }
        //console.log("SET ADV INDEX", index);
        draft.advertises[index]._id = payload._id;
        draft.advertises[index].createdAt = payload.createdAt;
        draft.advertises[index].interests = payload.interests;
        draft.advertises[index].invitations = payload.invitations;
        draft.advertises[index].price = payload.price;
        draft.advertises[index].deposit = payload.deposit;
        draft.advertises[index].from_date = payload.from_date;
        draft.advertises[index].to_date = payload.to_date;
        draft.advertises[index].eligible_tenant = payload.eligible_tenant;
        draft.advertises[index].preferences = payload.preferences;
        draft.advertises[index].utilities = payload.utilities;
        draft.advertises[index].title = payload.title;
        draft.advertises[index].description = payload.description;
        draft.advertises[index].n_beds = payload.n_beds;
        draft.advertises[index].n_busy_beds = payload.n_busy_beds;
        draft.advertises[index].photo_array = payload.photo_array;
        draft.advertises[index].videoId = payload.videoId;
        return;
      case SET_ADS:
        //console.log(action, payload);
        draft.advertises = payload;
        return;
      case SET_NOTIFICATION:
        draft.notifications = payload;
        return;
      case ADD_NOTIFICATION:
        //console.log("in redux add not", payload);
        draft.notifications.push(payload);
        return;
      case SET_NOTIFICATION_STATE:
        not_index = draft.notifications.findIndex(function (e) {
          if (e) {
            if (e.id == payload.id) return true;
          }
          return false;
        });
        if (not_index != -1) {
          var date = new Date();
          var time = date.getTime();
          draft.notifications[not_index].state = payload.state;
          draft.notifications[not_index].timestamp = time;
        }
        return;
      case READ_NOTIFICATION:
        draft.notifications.forEach((e, i) => {
          if (draft.notifications[i]) draft.notifications[i].read_mark = true;
        });
        return;
      case DELETE_ADVERTISE:
        draft.advertises = state.advertises.filter(
          (advertise) => advertise._id != payload
        );
        return;
      case CHANGE_ADS_ADDRESS:
        draft.advertises.forEach((advertise, i) => {
          draft.advertises[i].coordinates = payload.coordinates;
          draft.advertises[i].zone = payload.zone;
        });
        return;
      case CHANGE_PREFERENCES:
        draft.preferences = payload;
        return;
      case CHANGE_PROFILE_IMAGE:
        if (payload !== state.profile_image) draft.profile_image = payload;
        return;
      case ADD_PHOTO:
        index = state.photo_array.findIndex((image) => image.id == null);
        if (index !== -1) {
          draft.photo_array[index].img = payload.img;
          draft.photo_array[index].img_webp = payload.img_webp;
          draft.photo_array[index].img_small = payload.img_small;
          draft.photo_array[index].img_webp_small = payload.img_webp_small;
          draft.photo_array[index].id = payload.id;
        } else draft.photo_array.push(payload);
        return;
      case ADD_PHOTO_LOADER:
        for (i = 0; i < payload; i++) draft.photo_array.push({});
        return;
      case DELETE_PHOTO:
        index = state.photo_array.findIndex((x) => x.id == payload);
        //console.log("DELETE PHOTO", payload, index);
        if (index != -1) draft.photo_array.splice(index, 1);
        return;
      case CHANGE_WHATSAPP:
        draft.whatsappContact = {
          prefix: payload.prefix,
          number: payload.number,
        };
        return;
      case CHANGE_MESSENGER:
        draft.messengerContact = { name: payload.name, link: payload.link };
        return;
      case CHANGE_INSTAGRAM:
        draft.instagramContact = payload;
        return;
      case CHANGE_TIKTOK:
        draft.tiktokContact = payload;
        return;
      case CHANGE_TELEGRAM:
        draft.telegramContact = payload;
        return;
      case SET_NEW:
        draft.new = payload;
        return;
      case RESET_REDUX:
        return initialState;
    }
  });

export default homeReducer;
