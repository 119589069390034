import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({
  component: Component,
  restricted,
  isAuthenticating,
  redirect,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticating && restricted ? (
          <Redirect to={redirect ? redirect : "/"} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PrivateRoute;
