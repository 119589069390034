// RoundImage.js
import React, { useState, useEffect } from "react";
import ReactPlaceholder from "react-placeholder";
import {
  //TextBlock,
  //MediaBlock,
  //TextRow,
  //RectShape,
  RoundShape,
} from "react-placeholder/lib/placeholders";
import "react-placeholder/lib/reactPlaceholder.css";
import "./Loaders/Loader.css";
import userM from "@assets/default/user-m.png";

const RoundImage = (props) => {
  const [error, setError] = useState(false);
  const {
    className,
    diameter,
    orange,
    Svg,
    src,
    srcWebp,
    defaultImage,
    inLoading,
    alt,
    overlay,
    borderColor,
    background,
    variant,
  } = props;

  useEffect(() => {
    setError(false);
  }, [src, srcWebp]);

  const handleImageError = (event) => {
    //console.log("ERROR ON LOADING IMAGE", event.target.src);
    //event.target.src = defaultImage;
    setError(true);
  };

  return (
    <div className={className + " col-auto align-content-center "}>
      <div
        className="rounded-circle no-focus-outline"
        style={{
          width: diameter ? diameter : "100%",
          //overflow: "hidden",
          paddingBottom: diameter ? diameter : "100%",
          position: "relative",
          background: background ? background : "unset",
        }}
      >
        <ReactPlaceholder
          ready={!inLoading}
          customPlaceholder={
            <RoundShape
              className="mw-100 h-100"
              color="#dedede"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                objectFit: "cover",
              }}
            />
          }
        >
          {(srcWebp && srcWebp !== "") || (src && src !== "") || !Svg ? (
            <picture>
              {variant && !error && (
                <>
                  {srcWebp &&
                    srcWebp != "" &&
                    typeof srcWebp === "string" &&
                    srcWebp.search("large") && (
                      <source
                        type="image/webp"
                        srcSet={srcWebp.replace("large", variant)}
                      />
                    )}
                  {src &&
                    src != "" &&
                    typeof src === "string" &&
                    src.search("type=large") &&
                    variant === "medium" && (
                      <source
                        type="image/jpeg"
                        srcSet={src.replace("large", "large")}
                      />
                    )}
                  {src &&
                    src != "" &&
                    typeof src === "string" &&
                    src.search("large") && (
                      <source
                        type="image/jpeg"
                        srcSet={src.replace("large", variant)}
                      />
                    )}
                </>
              )}
              {srcWebp && srcWebp != "" && (
                <>
                  <source
                    type="image/webp"
                    srcSet={!error ? srcWebp : defaultImage}
                  />
                  <source
                    type="image/jpeg"
                    srcSet={!error ? src : defaultImage}
                  />
                </>
              )}
              <img
                src={!error ? src : defaultImage}
                onError={handleImageError}
                className={
                  "rounded-circle " +
                  (orange && !inLoading ? "  round-image " : " nopadding ")
                }
                alt={alt}
                style={{
                  fontSize: "10px",
                  overflow: "hidden",
                  position: "absolute",
                  borderColor: borderColor,
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </picture>
          ) : (
            <div
              className={
                "rounded-circle " +
                (orange && !inLoading ? "  round-image " : " nopadding ")
              }
              style={{
                position: "absolute",
                borderColor: borderColor,
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                height: "100%",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <Svg
                className="svg-thipibo-gray-color"
                style={{
                  position: "absolute",
                  top: "15%",
                  right: "15%",
                  left: "15%",
                  bottom: "15%",
                  height: "70%",
                  width: "70%",
                  objectFit: "cover",
                }}
              />
            </div>
          )}

          {overlay && overlay}
        </ReactPlaceholder>
      </div>
    </div>
  );
};

RoundImage.defaultProps = {
  orange: true,
  className: "",
  diameter: "",
  inLoading: false,
  alt: "avatar",
  defaultImage: userM,
  borderColor: "#FF7417",
  Svg: null,
  variant: "",
};

export default RoundImage;
