import React from "react";
import { askUserPermission } from "../../push-notifications";
import PushNotificationImg from "@assets/img/pushNotification.svg";
import {
  createNotificationSubscription,
  getUserSubscription,
} from "../../push-notifications";
import { withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { compose } from "redux";
import { setPushNotifModal } from "../../actions/general";
import { isPartner } from "../utility";

const mapStateToProps = (state) => {
  return {
    hasPushNotifModalOpened: state.General.hasPushNotifModalOpened,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPushNotifModal: () => dispatch(setPushNotifModal()),
  };
};
class PushNotificationsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    this.outer = document.createElement("div");
    this.outer.style.visibility = "hidden";
    this.outer.id = "PushModalButton";
    this.outer.type = "button";
    this.outer.addEventListener("click", (/*e*/) => {
      if (!this.props.hasPushNotifModalOpened && !isPartner()) {
        this.props.setPushNotifModal();
        this.toggleModal(true);
      }
    });
    document.body.appendChild(this.outer);

    //TEST
    //this.outer.click();
  }

  componentWillUnmount() {
    this.outer.parentNode.removeChild(this.outer);
  }

  toggleModal = (bool) => {
    this.setState({ isOpen: bool });
    if (bool) {
      window.history.pushState("popup-open", null, "");
      window.onpopstate = (e) => {
        e.preventDefault();
        this.setState({ isOpen: false });
        window.onpopstate = null;
      };
    } else {
      createNotificationSubscription();
      if (window.history.state === "popup-open") {
        window.onpopstate = null;
        window.history.replaceState("", null);
      }
    }
  };

  render() {
    return (
      <Modal
        show={this.state.isOpen}
        onHide={() => this.toggleModal(false)}
        dialogClassName="modal-dialog-centered modal-log-dialog"
      >
        <div className="modal-body">
          <div className="row">
            <div className="col-3">
              <PushNotificationImg
                className="svg-thipibo-color"
                height="100%"
                width="100%"
              />
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col-12">
                  <div className="thipi-text-h4-b pb-2">
                    Non perderti nessuna richiesta, abilita le notifiche anche
                    quando non sei connesso!
                  </div>
                </div>
                <div className="col-12 thipi-text-h6">
                  Potrai disattivarle in qualsiasi momento dal tuo browser.
                </div>
              </div>
            </div>

            <div className="col-12 text-right">
              <button
                className="btn thipi-btn-color mini-btn mx-1"
                onClick={() => this.toggleModal(false)}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PushNotificationsModal);
