import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import Modal from "react-bootstrap/Modal";

const mapStateToProps = (state) => {
  return {
    user: state.User,
  };
};

class NotLoggedModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      isOpen: false,
      loading: false,
      success: false,
    };
  }

  componentDidMount() {
    this.outer = document.createElement("div");
    this.outer.style.visibility = "hidden";
    this.outer.id = "NotLoggedModalButton";
    this.outer.type = "button";
    this.outer.addEventListener("click", (e) => {
      if (e.target.value) this.setState({ text: e.target.value });
      this.toggleModal(true);
    });
    document.body.appendChild(this.outer);

    //TEST
    //this.outer.click();
  }

  componentWillUnmount() {
    this.outer.parentNode.removeChild(this.outer);
  }

  loginClick = () => {
    this.toggleModal(false);
    setTimeout(() => document.getElementById("loginNavbarButton").click(), 100);
  };

  toggleModal = (bool) => {
    this.setState({ isOpen: bool });
    if (bool) {
      window.history.pushState("popup-open", null, "");
      window.onpopstate = (e) => {
        e.preventDefault();
        this.setState({ isOpen: false });
        window.onpopstate = null;
      };
    } else {
      if (window.history.state === "popup-open") window.history.back();
    }
  };

  render() {
    return (
      <Modal
        show={this.state.isOpen}
        onHide={() => this.toggleModal(false)}
        dialogClassName="modal-dialog-centered modal-log-dialog notLoggedModal"
      >
        <div className="modal-body text-center">
          <div className="modal-header no-borders">
            <button
              type="button"
              className="close modal-close position-absolute modal-close-top-right"
              onClick={() => this.toggleModal(false)}
            >
              ×
            </button>
          </div>
          <div className="thipi-text-h4">
            Per {this.state.text}{" "}
            <span className="modal-link" onClick={this.loginClick}>
              accedi o registrati
            </span>
          </div>
        </div>
      </Modal>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps))(NotLoggedModal);
