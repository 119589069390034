import {
  LOGGEDIN,
  MISSING_MODAL,
  PUSH_NOTIF_MODAL,
  SET_INVITATION,
  ADD_INVITATION,
  SET_NEWTENANTS,
  SET_NEWADS,
  SET_SELECTEDROOM,
  SET_NREAD,
  SET_STEP,
  SET_INAPP_WARNING,
  RESET_REDUX,
  SET_CITIES,
} from "../constants/action-general-types";

export const setLoggedIn = () => ({ type: LOGGEDIN });
export const setMissingDataModal = () => ({ type: MISSING_MODAL });
export const setPushNotifModal = () => ({ type: PUSH_NOTIF_MODAL });
export const setInvitation = (invitationList) => ({
  type: SET_INVITATION,
  payload: invitationList,
});
export const addInvitation = (ads_id, invitation) => ({
  type: ADD_INVITATION,
  payload: { ads_id, invitation },
});
export const setNewTenants = (users, count) => ({
  type: SET_NEWTENANTS,
  payload: { users, count },
});
export const setNewAds = (ads) => ({ type: SET_NEWADS, payload: ads });
export const setCities = (cities) => ({ type: SET_CITIES, payload: cities });
export const setSelectedRoom = (id) => ({
  type: SET_SELECTEDROOM,
  payload: id,
});
export const setNread = (nread) => ({ type: SET_NREAD, payload: nread });
export const setStep = (step) => ({ type: SET_STEP, payload: step });
export const setInappWarning = (read) => ({
  type: SET_INAPP_WARNING,
  payload: read,
});
export const resetRedux = () => ({ type: RESET_REDUX });
