import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { userCompleteness } from "../utility";

const mapStateToProps = (state) => {
  return {
    user: state.User,
  };
};

class NoCompleteUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    this.outer = document.createElement("div");
    this.outer.style.visibility = "hidden";
    this.outer.id = "NoCompleteUserButton";
    this.outer.type = "button";
    this.outer.addEventListener("click", (e) => {
      if (e.target.value) this.setState({ text: e.target.value });
      this.toggleModal(true);
    });
    document.body.appendChild(this.outer);

    //TEST
    //this.outer.click();
  }

  componentWillUnmount() {
    this.outer.parentNode.removeChild(this.outer);
  }

  toggleModal = (bool) => {
    this.setState({ isOpen: bool });
    if (bool) {
      window.history.pushState("popup-open", null, "");
      window.onpopstate = (e) => {
        e.preventDefault();
        this.setState({ isOpen: false });
        window.onpopstate = null;
      };
    } else {
      if (window.history.state === "popup-open") window.history.back();
    }
  };

  render() {
    const { user } = this.props;
    return (
      <Modal
        show={this.state.isOpen}
        onHide={() => this.toggleModal(false)}
        dialogClassName="modal-dialog-centered modal-log-dialog"
      >
        <div className="modal-body text-center">
          <div className="modal-header no-borders">
            <div className=" thipi-text-h3 pb-2">
              Forse dovremmo prima conoscerci meglio 😅
            </div>
            <button
              type="button"
              className="close modal-close position-absolute modal-close-top-right"
              onClick={() => this.toggleModal(false)}
            >
              ×
            </button>
          </div>
          <div className="thipi-text-h4">
            {user.name}, al momento il tuo profilo è completo al{" "}
            {userCompleteness(user)}%.
            <br />
            Troveremo gli annunci adatti a te ma{" "}
            <span
              onClick={() => {
                this.toggleModal(false);
              }}
            >
              <Link to="/user-profile">dicci qualcosa in più</Link>
            </span>
          </div>
        </div>
      </Modal>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps)
)(NoCompleteUserModal);
