export const SET_USER = "USER:SET_USER";
export const SET_LOGGED = "USER:SET_LOGGED";
export const SET_AGENDA = "USER:SET_AGENDA";
export const SET_NUM_SUPERPING = "USER:SET_NUM_SUPERPING";
export const SET_NOTE = "USER:SET_NOTE";
export const CHANGE_RESEARCH_INFO = "USER:CHANGE_RESEARCH_INFO";
//export const CHANGE_INFO = 'USER:CHANGE_INFO';
export const CHANGE_PERSONAL_INFO = "USER:CHANGE_PERSONAL_INFO";
export const CHANGE_CURR_COND = "USER:CHANGE_CURR_COND";
export const CHANGE_PERSONAL_ELEMENTS = "USER:CHANGE_PERSONAL_ELEMENTS";
export const CHANGE_TENANTS_NUMBER = "USER:CHANGE_TENANTS_NUMBER";
//export const CHANGE_TENANT = 'USER:CHANGE_TENANT';
export const CHANGE_IMAGE = "USER:CHANGE_IMAGE";
export const CHANGE_COVER_IMAGE = "USER:CHANGE_COVER_IMAGE";
export const CHANGE_VIDEO = "USER:CHANGE_VIDEO";
export const CHANGE_TEMPLATE = "USER:CHANGE_TEMPLATE";
export const CHANGE_SETTINGS = "USER:CHANGE_SETTINGS";
export const SET_HELP_SEEN = "USER:SET_HELP_SEEN";
export const SET_INVITE_HELP_SEEN = "USER:SET_INVITE_HELP_SEEN";
export const SET_PING_HELP_SEEN = "USER:SET_PING_HELP_SEEN";
export const SET_SUPERPING_HELP_SEEN = "USER:SET_SUPERPING_HELP_SEEN";
export const SET_SHARE_HELP_SEEN = "USER:SET_SHARE_HELP_SEEN";
export const SET_NEW_USER = "USER:SET_NEW_USER";
export const SET_TEMP_HOME = "USER:SET_TEMP_HOME";
export const SET_PROFILATION = "USER:SET_PROFILATION";
