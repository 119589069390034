import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ff7417",
      dark: "#e35b00",
      light: "#ff934a",
      contrastText: "#fff",
    },
    secondary: {
      main: "#4ca5a1",
      dark: "#3c827f",
      light: "#6abab7",
      contrastText: "#fff",
    },
    text: {
      primary: "#444444",
    },
  },
  typography: {
    fontFamily: [
      "Segoe UI",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          backdropFilter: "blur(3px)",
        },
        paper: ({ ownerState }) => ({
          borderRadius: ownerState.fullScreen ? "none" : "25px",
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              color: "#fff",
              ":hover": {
                color: "#fff",
              },
            }),
        }),
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: "var(--text-color)",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "8px",
        },
      },
    },
  },
});
