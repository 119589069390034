import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import FindRoomStepTwo from "@assets/home_page_img/findRoom.svg";
import FindRoomieStepFour from "@assets/home_page_img/findRoomie.svg";

function WhereYouGoModal() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.type = "button";
    outer.id = "whereYouGoModal";
    outer.addEventListener("click", () => {
      toggleModal(true);
    });
    document.body.appendChild(outer);
  }, []);

  const toggleModal = (bool) => {
    setIsOpen(bool);
    if (bool) {
      window.history.pushState("popup-open", null, "");
      window.onpopstate = (e) => {
        e.preventDefault();
        setIsOpen(false);
        window.onpopstate = null;
      };
    } else {
      if (window.history.state === "popup-open") {
        window.onpopstate = null;
        window.history.replaceState("", null);
      }
    }
  };
  return (
    <Modal
      show={isOpen}
      onHide={() => toggleModal(false)}
      dialogClassName="modal-dialog-centered modal-log-dialog"
    >
      <div className="modal-body">
        <div className="row">
          <Link
            to="/user-profile?tab=resElem"
            className="col-6 min-padding text-center"
            onClick={() => toggleModal(false)}
          >
            <FindRoomStepTwo />
          </Link>
          <Link
            to="/pubblica-annuncio-stanza-appartamento"
            className="col-6 min-padding text-center"
            onClick={() => toggleModal(false)}
          >
            <FindRoomieStepFour />
          </Link>
        </div>
        <div className="row">
          <Link
            to="/user-profile?tab=resElem"
            className="col-6 min-padding text-center"
            onClick={() => toggleModal(false)}
          >
            <button className="btn thipi-btn-color sm">Cerco Casa</button>
          </Link>
          <Link
            to="/pubblica-annuncio-stanza-appartamento"
            className="col-6 min-padding text-center"
            onClick={() => toggleModal(false)}
          >
            <button className="btn thipi-btn-color sm">Cerco Inquilino</button>
          </Link>
        </div>
      </div>
    </Modal>
  );
}
export default WhereYouGoModal;
