import React, { Component } from "react";
import RoundImage from "../RoundImage";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

class Notification extends Component {
  getTime = () => {
    var date = new Date();
    var nTime = this.props.timestamp;
    var time = date.getTime();
    var millisec = time - nTime;

    //var seconds = (millisec / 1000).toFixed(0);

    var minutes = (millisec / (1000 * 60)).toFixed(0);

    var hours = (millisec / (1000 * 60 * 60)).toFixed(0);

    var days = (millisec / (1000 * 60 * 60 * 24)).toFixed(0);

    var weeks = (millisec / (1000 * 60 * 60 * 24 * 7)).toFixed(0);

    var months = (millisec / (1000 * 60 * 60 * 24 * 30)).toFixed(0);

    var years = (millisec / (1000 * 60 * 60 * 24 * 30 * 12)).toFixed(0);

    if (minutes < 1) {
      return "adesso"; //(minutes == 1 ? " minuto" : " minuti");
    } else if (minutes < 60) {
      return minutes + " min."; //(minutes == 1 ? " minuto" : " minuti");
    } else if (hours < 24) {
      return hours + (hours == 1 ? " ora" : " ore");
    } else if (days < 7) {
      return days + " gio."; //(days == 1 ? " giorno" : " giorni");
    } else if (weeks < 4) {
      return weeks + " sett."; // (weeks == 1 ? " settimana" : " settimane");
    } else if (months < 12) {
      return months + (months == 1 ? " mese" : " mesi");
    } else if (years > 0) return years + (years == 1 ? " anno" : " anni");
    else return "1 min.";
  };

  getStyle = () => {
    if (this.props.read) return "notification-read";
    return "notification-unread";
  };

  render() {
    //console.log("render not", this.props);
    const { profile_image, profile_image_webp, children, user_id, ads_id } =
      this.props;
    return (
      <Dropdown.Item as="div" bsPrefix=" ">
        {(user_id || ads_id !== "-1") && (
          <div
            className={`row mh-100 mw-100 nopadding py-2 align-items-center justify-content-center dropdown-itemNot message-border message-link ${this.getStyle()}`}
          >
            <Link
              to={user_id ? "/inquilino/" + user_id : "/stanza/" + ads_id}
              className="nounderline col-auto text-center pr-0"
            >
              <RoundImage
                diameter="3em"
                src={profile_image}
                srcWebp={profile_image_webp}
                className="pr-0 pl-0"
              />
              <div className="pr-0 pl-0 text-truncate mw-100">
                {this.getTime()}
              </div>
            </Link>
            <div className="col">
              <div className="row">{children}</div>
            </div>
          </div>
        )}
        {!user_id && ads_id === "-1" && (
          <div
            className={`row mh-100 mw-100 nopadding py-2 align-items-center justify-content-center dropdown-itemNot message-border message-link ${this.getStyle()}`}
          >
            <div className="col-auto text-center pr-0 pl-0">
              <RoundImage
                diameter="3em"
                src={profile_image}
                srcWebp={profile_image_webp}
                className="pr-0"
              />
              <div className="text-truncate mw-100">{this.getTime()}</div>
            </div>
            <div className="col">
              <div className="row">{children}</div>
            </div>
          </div>
        )}
      </Dropdown.Item>
    );
  }
}

export default Notification;
