import {
  SET_RESEARCH,
  SET_TENANT,
  SET_TENANTS,
  RESET_RESEARCH,
  //CLEAR_TENANTS
} from "../constants/action-tenant-types";
import { RESET_REDUX } from "../constants/action-general-types";
import { produce } from "immer";

const initialState = {
  users: {}, // utenti indicizzati per user_id
  research: {
    advertise_id: -1,
    city: null,
    type: "",
    age: [],
    preferences: [],
    interests: [],
    lifestyles: [],
    ids: [],
  },
};

const tenantReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    // eslint-disable-next-line default-case
    switch (type) {
      case SET_RESEARCH:
        if (typeof payload.advertise_id !== "undefined")
          draft.research.advertise_id = payload.advertise_id;
        if (typeof payload.age !== "undefined")
          draft.research.age = payload.age;
        if (typeof payload.city !== "undefined")
          draft.research.city = payload.city;
        if (typeof payload.type !== "undefined")
          draft.research.type = payload.type;
        if (typeof payload.preferences !== "undefined")
          draft.research.preferences = payload.preferences;
        if (typeof payload.interests !== "undefined")
          draft.research.interests = payload.interests;
        if (typeof payload.lifestyles !== "undefined")
          draft.research.lifestyles = payload.lifestyles;
        if (typeof payload.ids !== "undefined")
          draft.research.ids = payload.ids;
        return;
      case SET_TENANT:
        draft.users[payload._id] = payload;
        return;
      case SET_TENANTS:
        payload.forEach((tenant) => {
          draft.users[tenant._id] = tenant;
        });
        return;
      case RESET_RESEARCH:
        draft.research = {
          advertise_id: -1,
          type: "",
          age: {},
          preferences: {},
          interests: [],
          lifestyles: [],
          ids: [],
        };
        return;
      case RESET_REDUX:
        return initialState;
      //case CLEAR_TENANTS:
      //return initialState;
    }
  });
export default tenantReducer;
