const RedirectTo = (props) => {
  // this.props.history is here
  props.history.replace(props.url);

  return <div />;
};

RedirectTo.defaultProps = {
  url: "/",
};

export default RedirectTo;
