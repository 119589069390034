import { useMediaQuery, useTheme } from "@mui/material";
import config from "../config";

export const scrollFieldIntoView = (event) => {
  //console.log(event.target)
  event.target.scrollIntoView(true);
};

export function toggleNotLoggedModal(text) {
  const button = document.getElementById("NotLoggedModalButton");
  button.value = text;
  button.click();
}

export function toggleNotVerifiedModal(text, cb) {
  const button = document.getElementById("NotVerifiedModalButton");
  button.value = { text, cb };
  button.click();
}

export function loginClick() {
  setTimeout(() => document.getElementById("loginNavbarButton").click(), 100);
}

export const userCompleteness = (user) => {
  let percentage = 0;
  if (!user.logged) return 0;
  if (user.seeking) {
    if (user.budget > 0) percentage += 8;
    if (user.room_types.length > 0) percentage += 8;
  } else {
    percentage += 16;
  }

  if (user.location.length > 3) percentage += 5;
  if (user.type !== "N") percentage += 5;

  if (user.isNotifMailConfirmed) percentage += 5;

  if (user.interests.length > 0) percentage += 10;
  if (user.lifestyles.length > 0) percentage += 10;

  //if (user.sport > -1) percentage += 5;
  if (user.weekend > -1) percentage += 5;
  if (user.alcol > -1) percentage += 5;
  if (user.smoke > -1) percentage += 5;
  if (user.animals > -1) percentage += 5;
  if (user.relationship > -1) percentage += 5;
  if (user.education > -1) percentage += 5;
  if (user.videoId && user.videoId.length > 0) percentage += 15;
  if (user.description.length >= 30) percentage += 10;
  if (!user.profile_image.includes("default")) percentage += 10;

  return Math.min(percentage, 100);
};

export const userMissingElements = (user) => {
  let missing = [];

  if (user.seeking && !isPartner()) {
    if (user.budget === 0) missing.push("Budget");
    if (user.room_types.length === 0) missing.push("Tipo di stanza ricercata");
  }

  if (user.location.length <= 3) missing.push("Provenienza");
  if (user.type === "N") missing.push("Condizione attuale");
  if (!user.isNotifMailConfirmed)
    missing.push("Mail di Notifica non confermata");

  if (!user.interests || user.interests.length === 0) missing.push("Interessi");
  if (!user.lifestyles || user.lifestyles.length === 0)
    missing.push("Abitudini");
  if (!user.active) missing.push("Account non verificato");
  if (
    user.weekend === -1 ||
    user.alcol === -1 ||
    user.smoke === -1 ||
    user.animals === -1
  )
    missing.push("Informazioni Utili");
  if (user.relationship === -1) missing.push("Relazione");
  if (user.education === -1) missing.push("Istruzione");
  if (!user.videoId || user.videoId.length === 0)
    missing.push("Videopresentazione");
  if (user.description.length < 30)
    missing.push("Descrizione (min. 30 caratteri)");
  if (user.profile_image.includes("default")) missing.push("Foto del Profilo");
  // if (!user.cover_image || user.cover_image.length === 0)
  //   missing.push("Foto di Copertina");

  return missing;
};

function useIsWidthUp(breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(breakpoint));
}
function useIsWidthDown(breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakpoint));
}

export function withWidthHook(Component, breakpoint) {
  return function WrappedComponent(props) {
    const isUp = useIsWidthUp(breakpoint ?? "sm");
    const isDown = useIsWidthDown(breakpoint ?? "sm");
    return <Component {...props} isUp={isUp} isDown={isDown} />;
  };
}

export function isPartner() {
  return !!getPartner();
}

export function getPartner() {
  const parts = window.location.hostname.split(".");
  const excludes = ["tcoll", "thipibo", "com", "www", "localhost"];
  const filteredParts = parts.filter((part) => !excludes.includes(part));
  const partner = filteredParts.length > 0 ? filteredParts[0] : null;

  return partner;
}

export async function fetcher({
  baseUrl,
  path,
  method,
  body,
  headers,
  credentials,
  ...rest
}) {
  const partner = getPartner();

  const partnerHeader = {};
  if (partner) {
    partnerHeader["X-Partner"] = partner.toUpperCase();
  }

  const r = await fetch(
    (baseUrl ??
      config.app.scheme +
        "://" +
        (partner ? partner + "." : "") +
        config.app.host +
        (config.app.port ? ":" + config.app.port : "")) + path,
    {
      method: method ?? "GET",
      body: body ? JSON.stringify(body) : undefined,
      credentials: credentials ? "include" : undefined,
      headers: {
        "Content-Type": "application/json",
        ...partnerHeader,
        ...headers,
      },
      ...rest,
    }
  );
  return await r.json();
}
