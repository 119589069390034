import { combineReducers } from 'redux';
import homeReducer from './home';
import userReducer from './user';
import advertiseReducer from './advertise';
import tenantReducer from './tenant';
import generalReducer from './general';
import conversationReducer from './conversation';

export default combineReducers({
  Home: homeReducer,
  User: userReducer,
  Advertise: advertiseReducer,
  Tenant: tenantReducer,
  General: generalReducer,
  Inbox: conversationReducer
});

//export default homeReducer;
