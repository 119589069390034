import io from "socket.io-client";
import config from "../config";

export default function socketMiddleware() {
  const socket = io(config.app.fullUrl);
  const ON = "ON";
  const EMIT = "EMIT";
  const EMIT_ROOM = "EMIT_ROOM";

  return ({ dispatch }) =>
    (next) =>
    (action) => {
      if (typeof action === "function") {
        return next(action);
      }

      const { event, leave, handle, room, type, ...rest } = action;

      if (!event) {
        return next(action);
      }

      if (leave) {
        return socket.removeListener(event);
      }
      /*
    let handleEvent = handle;
    if (typeof handleEvent === 'string') {
      handleEvent = result => dispatch({ type: handle, result, ...rest });
    }*/

      switch (type) {
        case ON: {
          //console.log (socket.id + " event on", handle)
          return socket.on(event, handle);
        }
        case EMIT: {
          //console.log (socket.id + " event emit", handle)
          return socket.emit(event, handle);
        }
        case EMIT_ROOM: {
          //console.log (socket.id + " event emit with room " + room, handle)
          return socket.in(room).emit(event, handle);
        }
      }
    };
}
