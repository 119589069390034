import config from "../config";

export const initGA = (trackingID, name, cookie) => {
  if (config.app.host === "thipibo.com") {
    window.gtag("consent", "update", {
      analytics_storage: cookie ? "granted" : "denied",
    });
  }
};

export const pageViewGA = () => {
  if (config.app.host === "thipibo.com") {
    window.gtag("event", "page_view", {
      page_path:
        window.location.pathname +
        window.location.search +
        window.location.hash,
      page_search: window.location.search,
      page_hash: window.location.hash,
    });
  } else {
    // console.log(
    //   "pageview",
    //   window.location.pathname + window.location.search + window.location.hash
    // );
  }
};

export const eventGA = (category, action, actor, city = "") => {
  if (config.app.host === "thipibo.com") {
    window.gtag("event", action, {
      category: category,
      action: action,
      actor: actor,
      city: city,
    });
  } else {
    // console.log("eventGA", {
    //   category: category,
    //   action: action,
    //   actor: actor,
    //   city: city,
    // });
  }
};

export const webVitalGA = (category, name, id, delta) => {
  if (config.app.host === "thipibo.com") {
    window.gtag("event", name, {
      category: category,
      web_vitals_measurement_name: name,
      web_vitals_measurement_id: id,
      web_vitals_measurement_value: delta,
    });
    eventGAWithValue(category, name, id, delta);
  } else {
    // console.log("webVitalGA", {
    //   category: category,
    //   web_vitals_measurement_name: name,
    //   web_vitals_measurement_id: id,
    //   web_vitals_measurement_value: delta,
    // });
  }
};

export const eventGAWithValue = (category, action, actor, city = "", value) => {
  if (config.app.host === "thipibo.com") {
    window.gtag("event", action, {
      category: category,
      action: action,
      actor: actor,
      city: city,
      value: value,
      nonInteraction: true,
    });
  } else {
    // console.log("eventGAWithValue", {
    //   category: category,
    //   action: action,
    //   actor: actor,
    //   city: city,
    //   value: value,
    //   nonInteraction: true,
    // });
  }
};

export const setIdGA = (id) => {
  if (config.app.host === "thipibo.com") {
    window.gtag("config", "G-C2C59LQV80", {
      user_id: id,
    });
  }
};
