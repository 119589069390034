/*export function subscribeMessages() {
  return {
    event: 'message',
    handle: NEW_MESSAGE,
  }
}

export function unsubscribeMessages() {
  return {
    event: 'message',
    leave: true,
  }
}*/

export const leave = (event) =>({event: event, leave: true});

export const onMessage = (event, handle) => ({event: event, handle: handle, type: 'ON'});

export const emitMessage = (event, handle) => ({event: event, handle: handle, type: 'EMIT'});

export const emitRoomMessage = (event, handle, room) => ({event: event, handle: handle, room: room, type: 'EMIT_ROOM'});
