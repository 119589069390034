import {
    SET_CONVERSATION,
    SET_CONVERSATION_PREVIEW,
    UPDATE_PREVIEW,
    READ_PREVIEW,
    READ_MESSAGE,
    ADD_TO_CONVERSATION
  } from "../constants/action-conversation-types";
  
  export const setConversation = conversations => ({type: SET_CONVERSATION, payload: conversations});
  export const addToConversation = conversations => ({type: ADD_TO_CONVERSATION, payload: conversations});
  export const setConversationPreview = previews => ({type: SET_CONVERSATION_PREVIEW, payload: previews});
  export const updatePreview = (conversation, read) => ({type: UPDATE_PREVIEW, payload: {conversation, read}});
  export const readPreview = conversation => ({type: READ_PREVIEW, payload: conversation});
  export const readMessage = (conv_id, user_id) => ({type: READ_MESSAGE, payload: {conv_id, user_id}});