//require("dotenv").config();
const env = import.meta.env;
const nodeEnv = env.VITE_STAGE || "development";

const development = {
  stage: "development",
  stripeKey:
    env.VITE_STRIPE_KEY ||
    "pk_test_51LihUNGTczjccAwT3QO9e07pRd6PRAvNw55nP1OA4oJqj7MaPYSHsvH4S3xHvu8KIPf7fBiPVGHdizDm1I9qaUZs00MYhpmneb",
  app: {
    scheme: env.VITE_SCHEME || "https",
    host: env.VITE_HOST || "localhost",
    port: parseInt(env.VITE_PORT) || 3001,
  },
};
development.app.fullUrl =
  development.app.scheme +
  "://" +
  development.app.host +
  (development.app.port ? ":" + development.app.port : "");

const test = {
  stage: "test",
  stripeKey:
    env.VITE_STRIPE_KEY ||
    "pk_test_51LihUNGTczjccAwT3QO9e07pRd6PRAvNw55nP1OA4oJqj7MaPYSHsvH4S3xHvu8KIPf7fBiPVGHdizDm1I9qaUZs00MYhpmneb",
  app: {
    scheme: env.VITE_SCHEME || "http",
    host: env.VITE_HOST || "localhost",
    port: parseInt(env.VITE_PORT) || 3001,
  },
};

test.app.fullUrl =
  test.app.scheme +
  "://" +
  test.app.host +
  (test.app.port ? ":" + test.app.port : "");

const production = {
  stage: "production",
  stripeKey: env.VITE_STRIPE_KEY,
  app: {
    scheme: env.VITE_SCHEME || "http",
    host: env.VITE_HOST || "localhost",
    port: parseInt(env.VITE_PORT),
  },
};
production.app.fullUrl =
  production.app.scheme +
  "://" +
  production.app.host +
  (production.app.port ? ":" + production.app.port : "");

const conf = {
  development,
  test,
  production,
};

export default conf[nodeEnv];
