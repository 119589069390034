// App
import React, { Suspense } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { fetcher, isPartner } from "./components/utility";
import Navbar from "./components/Navbar";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUser, userSetTempHome } from "./actions/user";
import { onMessage, emitMessage } from "./actions/socket";
import PrivateRoute from "./components/PrivateRoute";
import {
  setHome,
  homeSetSlots,
  homeSetAds,
  homeSetNotifications,
} from "./actions/home";
import { setLoggedIn, setCities } from "./actions/general";
import {
  setConversationPreview,
  addToConversation,
  updatePreview,
  readMessage,
} from "./actions/conversation";
//import { hot } from "react-hot-loader/root";
import { CookieBanner } from "./components/CookieBar/index";
import "./index.css";
import {
  pageViewGA,
  eventGA,
  initGA,
  eventGAWithValue,
  webVitalGA,
} from "./components/TrackerGA";
import reportWebVitals from "./reportWebVitals";
//import yall from "yall-js";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import { fbLogIn } from "./components/facebookUtility";
import RedirectTo from "./components/RedirectTo";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as Sentry from "@sentry/react";
import setDefaultOptions from "date-fns/setDefaultOptions";
import { it } from "date-fns/locale";
import { theme } from "./theme";
setDefaultOptions({ locale: it });

const Home = React.lazy(() => import("./pages/Home"));
// const Blog = React.lazy(() => import("./pages/Blog"));
const CreateHome = React.lazy(() => import("./pages/CreateHome"));
const CreateHomeStepContacts = React.lazy(() =>
  import("./pages/CreateHomeStepContacts")
);
const HowItWorks = React.lazy(() => import("./pages/HowItWorks"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const WhyCreateHome = React.lazy(() => import("./pages/WhyCreateHome"));
// const WhyUserProfile = React.lazy(() => import("./pages/WhyUserProfile"));
const UserProfile = React.lazy(() => import("./pages/UserProfile"));
const UserProfilation = React.lazy(() => import("./pages/UserProfilation"));
const UserProfilationStepContacts = React.lazy(() =>
  import("./pages/UserProfilationStepContacts")
);

const HomeProfile = React.lazy(() => import("./pages/HomeProfile"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const Terms = React.lazy(() => import("./pages/Terms"));
const CookieTerms = React.lazy(() => import("./pages/CookieTerms"));
const SearchAds = React.lazy(() => import("./pages/SearchAds"));
const SearchTenant = React.lazy(() => import("./pages/SearchTenant"));
const ViewAds = React.lazy(() => import("./pages/ViewAds"));
const ViewUser = React.lazy(() => import("./pages/ViewUser"));
const Notifications = React.lazy(() => import("./pages/Notifications"));
//const Agenda = React.lazy(() => import("./pages/Agenda"));
const HistoryInbox = React.lazy(() => import("./pages/HistoryInbox"));
const Messages = React.lazy(() => import("./pages/Messages"));
// const VideoChat = React.lazy(() => import("./pages/VideoChat"));
const Recover = React.lazy(() => import("./pages/Recover"));
const Settings = React.lazy(() => import("./pages/Settings"));
const VerifyUser = React.lazy(() => import("./pages/VerifyUser"));
const VerifyUserChangeMail = React.lazy(() =>
  import("./pages/VerifyUserChangeMail")
);
const Payments = React.lazy(() => import("./pages/Payments"));

const NoMatch = React.lazy(() => import("./pages/NoMatch"));
const PopupRedirect = React.lazy(() => import("./pages/PopupRedirect"));

const mapDispatchToProps = (dispatch) => {
  return {
    onMessage: (event, handle) => dispatch(onMessage(event, handle)),
    emitMessage: (event, handle) => dispatch(emitMessage(event, handle)),
    setUser: (user) => dispatch(setUser(user)),
    setHome: (home) => dispatch(setHome(home)),
    homeSetSlots: (slots) => dispatch(homeSetSlots(slots)),
    homeSetAds: (advertises) => dispatch(homeSetAds(advertises)),
    homeSetNotifications: (notifications) =>
      dispatch(homeSetNotifications(notifications)),
    setConversationPreview: (previews) =>
      dispatch(setConversationPreview(previews)),
    addToConversation: (conversations) =>
      dispatch(addToConversation(conversations)),
    updatePreview: (conversation, read) =>
      dispatch(updatePreview(conversation, read)),
    readMessage: (conv_id, user_id) => dispatch(readMessage(conv_id, user_id)),
    setLoggedIn: () => dispatch(setLoggedIn()),
    setCities: (cities) => dispatch(setCities(cities)),
    userSetTempHome: (saveObject) => dispatch(userSetTempHome(saveObject)),
  };
};

const mapStateToProps = (state) => {
  return {
    isAuthenticating: state.General.isAuthenticating,
    home_id: state.Home._id,
    newHome: state.Home.new,
    logged: state.User.logged,
    user_id: state.User._id,
    user_city: state.User.city,
    profiled: state.User.profiled,
    seeking: state.User.seeking,
    tempHome: state.User.tempHome,
  };
};

class App extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      buttonRef: null,
      cookieVisible: false,
      cookieStatistics: false,
    };
  }

  LogIn() {
    fetcher({ path: "/api/loggedIn", credentials: true })
      .then((res) => {
        if (!res.success) {
          //console.log("fallito loggedin");
          //Fix per Browser interni che non fanno loggare
          //if (isFacebookApp()) fbLogIn();
        } else {
          //const {_id,name,surname,active, mail, birth, gender, seeking, profile_image, sport, alcol, smoke, animals, relationship, education, occupation, location, studyplace, workplace, budget, from_date, to_date, alone, tenants, zone, description, requests} = res.user;
          res.user.logged = true;
          //console.log("SUCCESSO " + res.username + " " + this.state.logged);
          //console.log("successo loggedin. Utente ID", res.user);
          res.user.tenants.forEach((tenant, index) => {
            if (tenant.user_id) {
              let user_tenant = res.user_tenants.find(
                (x) => x._id == tenant.user_id
              );
              if (user_tenant) {
                //console.log("USER TENANT SET", user_tenant);
                let pickedUser = {};
                pickedUser.value = user_tenant._id;
                pickedUser.image = user_tenant.profile_image;
                pickedUser.image_webp = user_tenant.profile_image_webp;
                pickedUser.location = user_tenant.location;
                pickedUser.age = user_tenant.age;
                pickedUser.type = user_tenant.type;
                pickedUser.label = user_tenant.name + " " + user_tenant.surname;
                res.user.tenants[index].pickedUser = pickedUser;
                res.user.tenants[index].image = user_tenant.profile_image;
                res.user.tenants[index].image_webp =
                  user_tenant.profile_image_webp;
              }
            }
          });
          try {
            if (res.conversations != null) {
              this.props.setConversationPreview(res.conversations);
            }
          } catch (error) {
            //console.log("UE ERRORE CONV PREV", error);
          }
          this.props.setUser(res.user);
          this.setState({ user_id: res.user._id });
          if (res.home != null) {
            res.home.tenants.forEach((tenant, i_t) => {
              if (tenant.user_id) {
                let home_tenant = res.home_tenants.find(
                  (x) => x._id == tenant.user_id
                );
                if (home_tenant) {
                  let pickedUser = {};
                  pickedUser.value = home_tenant._id;
                  pickedUser.image = home_tenant.profile_image;
                  pickedUser.image_webp = home_tenant.profile_image_webp;
                  pickedUser.location = home_tenant.location;
                  pickedUser.age = home_tenant.age;
                  pickedUser.type = home_tenant.type;
                  pickedUser.label =
                    home_tenant.name + " " + home_tenant.surname;
                  res.home.tenants[i_t].pickedUser = pickedUser;
                  res.home.tenants[i_t].image = home_tenant.profile_image;
                  res.home.tenants[i_t].image_webp =
                    home_tenant.profile_image_webp;
                }
              }
            });
            if (res.ads != null) {
              res.home.advertises = res.ads;
            }
            this.props.setHome(res.home);

            if (res.slotArray != null) {
              this.props.homeSetSlots(res.slotArray);
            }
          }
          if (res.home != null) {
            this.props.emitMessage("login_home", res.home._id);
            this.props.emitMessage("login_user", res.user._id);
          } else this.props.emitMessage("login_user", res.user._id);
          //this.setState({logged: res.success});
          //console.log("logged: " + this.state.logged + "|");
        }
        if (res.notifications != null) {
          this.props.homeSetNotifications(res.notifications);
          //console.log("not log", res.notifications);
        }
        //this.setState({logged: res.success});
        //console.log("logged: " + this.state.logged + "|");
      })
      .then((/*res*/) => {
        this.props.setLoggedIn();
      })
      .catch((e) => console.log("Error during Fetch:", e));
  }

  checkProfiledUser() {
    if (this.props.logged && !this.props.profiled && this.props.seeking) {
      this.props.history.push({ pathname: "/completa-profilo" });
    }
  }

  loadCities = async () => {
    try {
      const res = await fetcher({
        path: "/api/getCities",
        method: "GET",
      });
      this.props.setCities(res.cities);
    } catch (e) {
      console.error("Error during Fetch:", e);
      Sentry.captureException(e);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.location.pathname !== this.props.location.pathname ||
      prevProps.logged !== this.props.logged ||
      prevProps.seeking !== this.props.seeking
    ) {
      this.checkProfiledUser();
    }
    if (prevState.cookieStatistics !== this.state.cookieStatistics) {
      initGA("UA-158520293-1", "trackerApp", this.state.cookieStatistics);

      pageViewGA();

      reportWebVitals(({ name, delta, id }) => {
        webVitalGA(
          "Web Vitals",
          name,
          id,
          Math.round(name === "CLS" ? delta * 1000 : delta)
        );
      });

      eventGA(
        "NAVIGAZIONE",
        window.location.pathname + window.location.search,
        this.props.user_id > 0 ? "User_" + this.props.user_id : "New_User",
        this.props.user_city
      );

      this.unlisten = this.props.history.listen((/*location, action*/) => {
        //pageViewGA();
        eventGA(
          "NAVIGAZIONE",
          window.location.pathname + window.location.search,
          this.props.user_id > 0 ? "User_" + this.props.user_id : "New_User",
          this.props.user_city
        );
      });
    }
  }

  componentDidMount() {
    if (!Cookies.get("_ga")) {
      initGA("UA-158520293-1", "trackerApp", false);
    } else {
      initGA("UA-158520293-1", "trackerApp", true);

      pageViewGA();

      reportWebVitals(({ name, delta, id }) => {
        eventGAWithValue(
          "Web Vitals",
          name,
          id,
          Math.round(name === "CLS" ? delta * 1000 : delta)
        );
      });

      eventGA(
        "NAVIGAZIONE",
        window.location.pathname + window.location.search,
        this.props.user_id > 0 ? "User_" + this.props.user_id : "New_User",
        this.props.user_city
      );

      this.unlisten = this.props.history.listen((/*location, action*/) => {
        pageViewGA();
        eventGA(
          "NAVIGAZIONE",
          window.location.pathname + window.location.search,
          this.props.user_id > 0 ? "User_" + this.props.user_id : "New_User",
          this.props.user_city
        );
      });
    }

    setTimeout(() => this.setState({ cookieVisible: true }), 1500);

    this.props.onMount();

    this.checkProfiledUser();

    this.loadCities();

    if (window.location.hash) {
      var params = window.location.hash.substr(1).split("&");

      for (var i = 0; i < params.length; i++) {
        var a = params[i].split("=");
        // Now every parameter from the hash is beind handled this way
        if (a[0] === "access_token") {
          this.props.history.push({ search: "" });
          fbLogIn(a[1]);
        } else if (a[0] === "cmp") {
          const campaign = a[1];
          this.props.userSetTempHome({ campaign: campaign });
          this.tempSave({ ...this.props.tempHome, campaign: campaign });
          eventGA(
            "CAMPAIGN",
            campaign,
            this.props.user_id > 0 ? "User_" + this.props.user_id : "New_User",
            this.props.user_city
          );
          window.location.hash = "";
        }
      }
    }

    //Lazy Loading
    //document.addEventListener("DOMContentLoaded", yall);

    this.LogIn();
    this.props.onMessage("New_message", (conv) => {
      if (window.location.pathname != "/messages") {
        this.props.addToConversation(conv);
        this.props.updatePreview(conv[0]);
      } else {
        let params = new URLSearchParams(window.location.href);
        if (params.has("a")) {
          if (
            window.location.pathname == "/messages" &&
            params.get("a") != conv[0].conversation_id
          ) {
            this.props.updatePreview(conv[0]);
          }
        }
      }
    });
    this.props.onMessage("Read_message", (reader) => {
      if (window.location.pathname != "/messages") {
        this.props.readMessage(reader.conv_id, reader.user_id);
      }
    });
    this.props.onMessage("New_conversation", (conv) => {
      //console.log("socket new conv", conv);
      this.props.setConversationPreview(conv);
    });
  }

  componentWillUnmount() {
    this.unlisten?.();
  }

  onRefChange = (node) => {
    //console.log("REF CHANGE", node);
    this.setState({ buttonRef: node });
  };

  tempSave = (saveObject) => {
    //console.log("TEMP SAVE", saveObject, this.props.tempHome);
    localStorage.setItem(
      "tempHome",
      JSON.stringify(saveObject || this.props.tempHome)
    );
  };

  render() {
    const {
      home_id,
      newHome,
      subscribed,
      subscribedBasic,
      logged,
      isAuthenticating,
      profiled,
      seeking,
    } = this.props;
    return (
      <div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Helmet>
                <meta charSet="utf-8" />
                <meta
                  name="description"
                  content="Trova su Thipibo la tua nuova casa, appartamento o stanza in affitto. Oppure cerca l'inquilino ideale, tanti studenti e lavoratori fuorisede si sono già iscritti."
                />
                <title>
                  Thipibo - Cerca stanze, appartamenti in affitto e coinquilini
                </title>
              </Helmet>
              <Navbar buttonRef={this.state.buttonRef} />
              <Suspense fallback={null}>
                {!isPartner() ? (
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route
                      restricted={home_id === -1}
                      isAuthenticating={isAuthenticating}
                      path="/pubblica-annuncio-stanza-appartamento/contatti"
                      // redirect="/pubblica-annuncio-stanza-appartamento"
                      component={CreateHomeStepContacts}
                    />
                    <PrivateRoute
                      restricted={home_id !== -1 && !newHome}
                      isAuthenticating={isAuthenticating}
                      path="/pubblica-annuncio-stanza-appartamento"
                      redirect="/home-profile"
                      component={CreateHome}
                    />
                    <PrivateRoute
                      restricted={!logged}
                      isAuthenticating={isAuthenticating}
                      path="/completa-profilo/contatti"
                      redirect="/"
                      component={UserProfilationStepContacts}
                    />
                    <PrivateRoute
                      restricted={!logged}
                      isAuthenticating={isAuthenticating}
                      path="/completa-profilo"
                      redirect="/"
                      component={UserProfilation}
                    />
                    <PrivateRoute
                      restricted={!logged}
                      isAuthenticating={isAuthenticating}
                      path="/user-profile"
                      component={UserProfile}
                    />
                    <PrivateRoute
                      restricted={home_id === -1}
                      isAuthenticating={isAuthenticating}
                      path="/home-profile"
                      component={HomeProfile}
                    />
                    <Route
                      path="/cerca-annunci-stanza-appartamento/:city"
                      render={(props) => (
                        <RedirectTo {...props} url="/affitto/:city" />
                      )}
                    />
                    <Route
                      path="/cerca-casa-stanza/:city"
                      render={(props) => (
                        <RedirectTo {...props} url="/affitto/:city" />
                      )}
                    />
                    <Route path="/affitto/:city" component={SearchAds} />
                    <Route
                      path="/cerca-inquilino/:city"
                      component={SearchTenant}
                    />
                    <Route path="/stanza/:advertiseId" component={ViewAds} />
                    <Route path="/inquilino/:profileId" component={ViewUser} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/cookie-terms" component={CookieTerms} />
                    <Route path="/terms" component={Terms} />
                    <Route
                      path="/why-create-home"
                      render={(props) => (
                        <WhyCreateHome
                          {...props}
                          refProp={this.state.buttonRef}
                          buttonRef={this.onRefChange}
                        />
                      )}
                    />
                    <Route path="/come-funziona" component={HowItWorks} />
                    <Route path="/about-us" component={AboutUs} />
                    <PrivateRoute
                      restricted={!logged}
                      isAuthenticating={isAuthenticating}
                      path="/notifications"
                      component={Notifications}
                    />
                    <PrivateRoute
                      restricted={!logged}
                      isAuthenticating={isAuthenticating}
                      path="/inbox"
                      component={HistoryInbox}
                    />
                    <PrivateRoute
                      restricted={!logged}
                      isAuthenticating={isAuthenticating}
                      path="/messages"
                      component={Messages}
                    />
                    <PrivateRoute
                      restricted={!logged}
                      isAuthenticating={isAuthenticating}
                      path="/settings"
                      component={Settings}
                    />
                    <Route path="/recover" component={Recover} />
                    <Route path="/verifyUser" component={VerifyUser} />
                    <PrivateRoute
                      restricted={subscribed || subscribedBasic}
                      isAuthenticating={isAuthenticating}
                      path="/pricing"
                      component={Payments}
                    />
                    <Route
                      path="/ChangeMail"
                      component={VerifyUserChangeMail}
                    />
                    <Route path="/popup-redirect" component={PopupRedirect} />
                    <Route component={NoMatch} />
                  </Switch>
                ) : (
                  <Switch>
                    <Route exact path="/" component={SearchTenant} />
                    <Route path="/inquilino/:profileId" component={ViewUser} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/cookie-terms" component={CookieTerms} />
                    <Route path="/terms" component={Terms} />
                    <PrivateRoute
                      restricted={!logged}
                      isAuthenticating={isAuthenticating}
                      path="/completa-profilo/contatti"
                      redirect="/"
                      component={UserProfilationStepContacts}
                    />
                    <PrivateRoute
                      restricted={!logged}
                      isAuthenticating={isAuthenticating}
                      path="/completa-profilo"
                      redirect="/"
                      component={UserProfilation}
                    />
                    <PrivateRoute
                      restricted={!logged}
                      isAuthenticating={isAuthenticating}
                      path="/user-profile"
                      component={UserProfile}
                    />
                    <PrivateRoute
                      restricted={!logged}
                      isAuthenticating={isAuthenticating}
                      path="/notifications"
                      component={Notifications}
                    />
                    <PrivateRoute
                      restricted={!logged}
                      isAuthenticating={isAuthenticating}
                      path="/inbox"
                      component={HistoryInbox}
                    />
                    <PrivateRoute
                      restricted={!logged}
                      isAuthenticating={isAuthenticating}
                      path="/messages"
                      component={Messages}
                    />
                    <PrivateRoute
                      restricted={!logged}
                      isAuthenticating={isAuthenticating}
                      path="/settings"
                      component={Settings}
                    />
                    <Route path="/recover" component={Recover} />
                    <Route path="/verifyUser" component={VerifyUser} />
                    <PrivateRoute
                      restricted={subscribed || subscribedBasic}
                      isAuthenticating={isAuthenticating}
                      path="/pricing"
                      component={Payments}
                    />
                    <Route
                      path="/ChangeMail"
                      component={VerifyUserChangeMail}
                    />
                    <Route path="/popup-redirect" component={PopupRedirect} />
                    {/* Do not move this, it's a catch all route */}
                    <Route path="/:city" component={SearchTenant} />
                  </Switch>
                )}
              </Suspense>
              {this.state.cookieVisible && (
                <>
                  <div className="d-flex d-md-none">
                    <CookieBanner
                      message="Hai fame? Prendi un biscotto! Utilizziamo i cookie per offrirti la migliore esperienza
                possibile."
                      policyLink="/cookie-terms"
                      privacyPolicyLinkText="Utilizzo dei Cookie"
                      necessaryOptionText="Necessari"
                      preferencesOptionText="Preferenze"
                      statisticsOptionText="Statistiche"
                      marketingOptionText="Marketing"
                      acceptButtonText="Accetta"
                      declineButtonText="Rifiuta"
                      managePreferencesButtonText="Gestisci preferenze"
                      savePreferencesButtonText="Salva preferenze"
                      showDeclineButton={false}
                      onAcceptStatistics={() => {
                        if (!this.state.cookieStatistics) {
                          this.setState({ cookieStatistics: true });
                        }
                      }}
                      styles={{
                        container: { maxWidth: "none" },
                        dialog: {
                          position: "fixed",
                          bottom: "0",
                          left: "0",
                          right: "0",
                          zIndex: "100000",
                          color: "white",
                          fontWeight: "400",
                          borderRadius: "5px",
                          backgroundColor: "rgba(94, 94, 94, 0.75)",
                          padding: "10px",
                          marginBottom:
                            "calc(3em + env(safe-area-inset-bottom))",
                        },
                        message: {
                          color: "white",
                        },
                        policy: {
                          color: "white",
                          textDecoration: "underline",
                          fontWeight: "300",
                        },
                        button: {
                          backgroundColor: "#FF7417",
                          //  fontSize: "13px",
                          color: "white",
                          borderRadius: "5px",
                          marginColor: "#FF7417",
                          border: "solid",
                          borderColor: "#FF7417",
                          margin: "10px",
                        },
                        secondaryButton: {
                          backgroundColor: "rgba(94, 94, 94, 0.75)",
                          color: "white",
                          borderRadius: "5px",
                          border: "solid",
                          borderColor: "white",
                          margin: "10px",
                        },
                      }}
                    />
                  </div>
                  <div className="d-none d-md-flex">
                    <CookieBanner
                      message="Hai fame? Prendi un biscotto! Utilizziamo i cookie per offrirti la migliore esperienza
                possibile."
                      policyLink="/cookie-terms"
                      privacyPolicyLinkText="Utilizzo dei Cookie"
                      necessaryOptionText="Necessari"
                      preferencesOptionText="Preferenze"
                      statisticsOptionText="Statistiche"
                      marketingOptionText="Marketing"
                      acceptButtonText="Accetta"
                      declineButtonText="Rifiuta"
                      managePreferencesButtonText="Gestisci preferenze"
                      savePreferencesButtonText="Salva preferenze"
                      showDeclineButton={false}
                      onAcceptStatistics={() => {
                        if (!this.state.cookieStatistics) {
                          this.setState({ cookieStatistics: true });
                        }
                      }}
                      styles={{
                        container: { maxWidth: "none" },
                        dialog: {
                          position: "fixed",
                          bottom: "0",
                          left: "0",
                          right: "0",
                          zIndex: "100000",
                          color: "white",
                          fontWeight: "400",
                          borderRadius: "5px",
                          backgroundColor: "rgba(94, 94, 94, 0.75)",
                          padding: "10px",
                        },
                        message: {
                          color: "white",
                        },
                        policy: {
                          color: "white",
                          textDecoration: "underline",
                          fontWeight: "300",
                        },
                        button: {
                          backgroundColor: "#FF7417",
                          //  fontSize: "13px",
                          color: "white",
                          borderRadius: "5px",
                          marginColor: "#FF7417",
                          border: "solid",
                          borderColor: "#FF7417",
                          margin: "10px",
                        },
                        secondaryButton: {
                          backgroundColor: "rgba(94, 94, 94, 0.75)",
                          color: "white",
                          borderRadius: "5px",
                          border: "solid",
                          borderColor: "white",
                          margin: "10px",
                        },
                      }}
                    />
                  </div>
                </>
              )}
            </ThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </div>
    );
  }
}
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
