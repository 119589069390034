export const SET_HOME = "HOME:SET_HOME";
export const CREATE_BEDROOM = "HOME:CREATE_BEDROOM";
export const SET_BEDROOM = "HOME:SET_BEDROOM";
export const ADD_BUSY_BED = "HOME:ADD_BUSY_BED";
export const DELETE_BUSY_BED = "HOME:DELETE_BUSY_BED";
export const DELETE_BEDROOM = "HOME:DELETE_BEDROOM";
export const CHANGE_ADDRESS = "HOME:CHANGE_ADDRESS";
export const CHANGE_TYPE = "HOME:CHANGE_TYPE";
export const CHANGE_ADS_ADDRESS = "HOME:CHANGE_ADS_ADDRESS";
export const CHANGE_NAME = "HOME:CHANGE_NAME";
export const CHANGE_BATHROOMS_NUMBER = "HOME:CHANGE_BATHROOMS_NUMBER";
export const CHANGE_OTHERROOM = "HOME:CHANGE_OTHERROOM";
export const CHANGE_SERVICE = "HOME:CHANGE_SERVICE";
export const SET_IF_OWNER_IN_HOME = "HOME:SET_IF_OWNER_IN_HOME";
export const CHANGE_TENANTS = "HOME:CHANGE_TENANTS";
export const DELETE_TENANT = "HOME:DELETE_TENANT";
export const CHANGE_SLOT = "HOME:CHANGE_SLOT";
export const SET_SLOT = "HOME:SET_SLOT";
export const ADD_USER_IN_SLOT = "HOME:ADD_USER_IN_SLOT";
export const SET_STEP_NUMBER = "HOME:SET_STEP_NUMBER";
export const ADV_CHANGE_TENANT = "HOME:ADV_CHANGE_TENANT";
export const SET_ADVERTISE = "HOME:SET_ADVERTISE";
export const DELETE_ADVERTISE = "HOME:DELETE_ADVERTISE";
export const SET_ADS = "HOME:SET_ADS";
export const SET_NOTIFICATION = "HOME:SET_NOTIFICATION";
export const SET_NOTIFICATION_STATE = "HOME:SET_NOTIFICATION_STATE";
export const ADD_NOTIFICATION = "HOME:ADD_NOTIFICATION";
export const READ_NOTIFICATION = "HOME:READ_NOTIFICATION";
export const ADD_PHOTO = "HOME:ADD_PHOTO";
export const ADD_PHOTO_LOADER = "HOME:ADD_PHOTO_LOADER";
export const DELETE_PHOTO = "HOME:DELETE_PHOTO";
export const CHANGE_PROFILE_IMAGE = "HOME:CHANGE_PROFILE_IMAGE";
export const CHANGE_PREFERENCES = "HOME:CHANGE_PREFERENCES";
export const CHANGE_DESCRIPTION = "HOME:CHANGE_DESCRIPTION";
export const CHANGE_SQUARE = "HOME:CHANGE_SQUARE";
export const ADD_INVITATION = "HOME:ADD_INVITATION";
export const CHANGE_WHATSAPP = "HOME:CHANGE_WHATSAPP";
export const CHANGE_MESSENGER = "HOME:CHANGE_MESSENGER";
export const CHANGE_INSTAGRAM = "HOME:CHANGE_INSTAGRAM";
export const CHANGE_TIKTOK = "HOME:CHANGE_TIKTOK";
export const CHANGE_TELEGRAM = "HOME:CHANGE_TELEGRAM";
export const SET_NEW = "HOME:SET_NEW";
