// Registration.js
import React, { Component } from "react";
import "@assets/Feelmehome.css";
import "./Signup.css";
import { connect } from "react-redux";
import { compose } from "redux";
import { setUser, userSetNew } from "../actions/user";
import { fetcher } from "../components/utility";
import * as dateFns from "date-fns";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import {
  TextField,
  MenuItem,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  adaptV4Theme,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { withWidthHook } from "../components/utility";

import { legacyParse, convertTokens } from "@date-fns/upgrade/v2";
import Button from "../components/Button";

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#ff7417",
      },
    },
  })
);

const mapStateToProps = (state) => {
  return {
    logged: state.User.logged,
    newUser: state.User.new,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
    userSetNew: (bool) => dispatch(userSetNew(bool)),
  };
};

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      convPass: true,
      convMail: true,
      convAge: true,
      convName: true,
      convSurname: true,
      convGender: true,
      convTerms: true,
      name: "",
      surname: "",
      mail: "",
      //cMail: "",
      password: "",
      cPassword: "",
      gender: "",
      day: null,
      month: null,
      year: null,
      invalidMailText: "",
      invalidPasswordText: "",
      terms: false,
      marketing: false,
      //done: false,
      saving: false,
      notVerified: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newUser !== this.props.newUser && this.props.newUser) {
      const { pathname } = this.props.history.location;
      /*if (pathname === "/") {
        setTimeout(
          () => document.getElementById("whereYouGoModal").click(),
          300
        );
      } else if (
        pathname !== "/why-create-home" &&
        pathname !== "/pubblica-annuncio-stanza-appartamento"
      )
        setTimeout(
          () => this.props.history.push("/user-profile?tab=resElem"),
          300
        );
      else {
        this.props.userSetNew(false);
        this.setState({ notVerified: true });
      }*/
    }
  }

  validPassword = (password, cPassword) => {
    if (password !== cPassword) {
      this.setState({ invalidPasswordText: "Le password non corrispondono" });
      return false;
    }
    var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    if (password.match(regex)) {
      return true;
    } else {
      this.setState({
        invalidPasswordText:
          "Password non valida (deve contenere almeno un carattere maiuscolo, uno minuscolo ed un numero)",
      });
      return false;
    }
  };

  validMail = (mail /*, cMail*/) => {
    //if (mail !== cMail) return false;

    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //console.log(regex + " " + mail)
    return regex.test(String(mail)?.toLowerCase());
  };

  validAge = (birth) => {
    if (Number.isNaN(birth)) {
      return false;
    } else {
      var ageLimit = 18;

      const today = dateFns.format(
        legacyParse(new Date()),
        convertTokens("YYYYMMDD")
      );
      const age = Math.floor((today - birth) / 10000);

      if (age < ageLimit) return false;
      else return true;
    }
  };

  validText = (text) => {
    if (text === "") return false;
    else {
      var regex = /^([^0-9]*)$/;
      return regex.test(String(text)?.toLowerCase());
    }
  };

  validGender = (gender) => {
    if (gender === "n" || gender === "") return false;
    else return true;
  };

  validTerms = () => {
    if (this.state.terms) return true;
    else return false;
  };

  submitNewUser = () => {
    const {
      name,
      surname,
      mail,
      //cMail,
      password,
      cPassword,
      gender,
      year,
      month,
      day,
      marketing,
    } = this.state;
    const birth =
      parseInt(year) * 10000 + parseInt(month) * 100 + parseInt(day);
    const convPass = this.validPassword(password, cPassword);
    const convMail = this.validMail(mail /*, cMail*/);
    if (!convMail) {
      this.setState({ invalidMailText: "Mail non valida" });
    }
    const convAge = this.validAge(birth);
    const convName = this.validText(name);
    const convSurname = this.validText(surname);
    const convGender = this.validGender(gender);
    const convTerms = this.validTerms();

    this.setState({
      convPass: convPass,
      convMail: convMail,
      convAge: convAge,
      convName: convName,
      convSurname: convSurname,
      convGender: convGender,
      convTerms: convTerms,
    });

    if (
      convName &&
      convSurname &&
      convPass &&
      convMail &&
      convAge &&
      convGender &&
      convTerms
    ) {
      this.setState({ saving: true });
      fetcher({
        path: "/api/signUp",
        //credentials: 'include',
        method: "POST",
        body: {
          name,
          surname,
          mail,
          //cMail,
          password,
          gender,
          birth,
          marketing,
        },
      })
        .then((res) => {
          // BRING THIS OUT SOMEDAY
          if (!res.success) {
            this.setState({ saving: false });
            throw Error(res.statusText);
          }
          return res;
        })
        .then((res) => {
          if (!res.success) {
            this.setState({ saving: false });
            console.log(res.error);
            switch (res.error_code) {
              case 1:
                this.setState({
                  invalidMailText:
                    "Mail già utilizzata con il tuo account Facebook",
                });
                this.setState({ convMail: false });
                break;
              case 2:
                this.setState({
                  invalidMailText:
                    "Mail già utilizzata con il tuo account Google",
                });
                this.setState({ convMail: false });
                break;
              case 3:
                this.setState({ invalidMailText: "Mail già utilizzata" });
                this.setState({ convMail: false });
                break;
              default:
            }
          } else {
            const remember = false;
            fetcher({
              path: "/api/logIn",
              credentials: true,
              redirect: "follow",
              method: "POST",
              body: { mail, password, remember },
            })
              .then((res) => {
                if (!res.success) console.log("fallito");
                else {
                  //const {_id,name,surname,active, mail, birth, gender, seeking, profile_image, sport, alcol, smoke, animals, relationship, education, occupation, location, studyplace, workplace, budget, from_date, to_date, alone, tenants, zone, description} = res.user;
                  res.user.logged = res.success;
                  res.user.new = true;
                  res.user.pwaInstallSeen = true;
                  //console.log("SUCCESSO " + res.username + " " + this.state.logged);
                  // console.log("successo loggedin. Utente ID", res.user);
                  this.props.setUser(res.user);
                  //this.setDone(true);
                  setTimeout(
                    document.getElementById("closing_modal").click(),
                    50
                  );
                  /*setTimeout(
                    document.getElementById("missingData").click(),
                    500
                  );*/
                  //console.log("logged: " + this.state.logged + "|");
                }
              })
              .catch((e) => console.log("Error during Fetch:", e));
          }
        })
        .catch((error) => console.log(error));
    } //end if conv*/ //end of timeout
    /*  }, 500); */ //end of timeout
  };

  /*setDone = (bool) => {
    this.setState({ done: bool });
  };*/

  checkNameState = (event) => {
    this.setState({ name: event.target.value });
  };

  checkSurnameState = (event) => {
    this.setState({ surname: event.target.value });
  };

  checkMailState = (event) => {
    this.setState({ mail: event.target.value });
  };

  /*checkCMailState = event => {
    this.setState({ cMail: event.target.value });
  };*/

  checkPasswordState = (event) => {
    this.setState({ password: event.target.value });
  };

  checkCPasswordState = (event) => {
    this.setState({ cPassword: event.target.value });
  };

  checkMonthState = (event) => {
    this.setState({ month: event.target.value });
    const n_days = dateFns.getDaysInMonth(
      legacyParse(new Date(this.state.year, event.target.value - 1))
    );
    if (n_days < this.state.day) this.setState({ day: n_days });
  };

  checkYearState = (event) => {
    this.setState({ year: event.target.value });
    const n_days = dateFns.getDaysInMonth(
      legacyParse(new Date(event.target.value, this.state.month - 1))
    );
    if (n_days < this.state.day) {
      this.setState({ day: n_days });
    }
  };

  checkDayState = (event) => {
    this.setState({ day: event.target.value });
  };

  checkGenderState = (e) => {
    this.setState({ gender: e.target.value });
  };

  checkTermsState = (/*event*/) => {
    this.setState({ terms: !this.state.terms });
  };

  checkMarketingState = (/*event*/) => {
    this.setState({ marketing: !this.state.marketing });
  };

  showDays = (convAge, month, year) => {
    let native = !this.props.isUp;
    var days = [];
    let n_days = dateFns.getDaysInMonth(legacyParse(new Date(year, month - 1)));
    if (!n_days) n_days = 31;

    if (native) {
      days.push(<option value={null} disabled selected hidden></option>);

      for (var i = 1; i <= n_days; i++) {
        days.push(<option value={i}> {i} </option>);
      }
    } else {
      days.push(
        <MenuItem value={null} disabled selected hidden>
          Giorno
        </MenuItem>
      );

      for (var i = 1; i <= n_days; i++) {
        days.push(<MenuItem value={i}> {i} </MenuItem>);
      }
    }
    return (
      <TextField
        select
        variant="outlined"
        size="small"
        fullWidth
        error={!convAge}
        autoComplete="bday-day"
        onChange={this.checkDayState}
        value={this.state.day}
        label="Giorno"
        SelectProps={{
          native,
        }}
        helperText={!convAge ? "Età non valida" : null}
      >
        {days}
      </TextField>
    );
  };

  showMonths = (convAge) => {
    let native = !this.props.isUp;
    var months = [];
    const n_months = 12;

    if (native) {
      months.push(<option value={null} disabled selected hidden></option>);

      for (var i = 1; i <= n_months; i++) {
        months.push(<option value={i}> {this.numToMonths(i)} </option>);
      }
    } else {
      months.push(
        <MenuItem value={null} disabled selected hidden>
          Mese
        </MenuItem>
      );

      for (var i = 1; i <= n_months; i++) {
        months.push(<MenuItem value={i}> {this.numToMonths(i)} </MenuItem>);
      }
    }
    return (
      <TextField
        select
        variant="outlined"
        size="small"
        fullWidth
        error={!convAge}
        onChange={this.checkMonthState}
        autoComplete="bday-month"
        value={this.state.month}
        label="Mese"
        SelectProps={{
          native,
        }}
      >
        {months}
      </TextField>
    );
  };

  showYears = (convAge) => {
    let native = !this.props.isUp;
    var years = [];
    const first_year = 1940;
    const last_year = 2005;
    if (native) {
      years.push(<option value={null} disabled selected hidden></option>);
      for (var i = last_year; i >= first_year; i--) {
        years.push(<option value={i}> {i} </option>);
      }
    } else {
      years.push(
        <MenuItem value={null} disabled selected hidden>
          Anno
        </MenuItem>
      );
      for (var i = last_year; i >= first_year; i--) {
        years.push(<MenuItem value={i}> {i} </MenuItem>);
      }
    }
    return (
      <TextField
        select
        variant="outlined"
        size="small"
        fullWidth
        error={!convAge}
        onChange={this.checkYearState}
        autoComplete="bday-year"
        value={this.state.year}
        label="Anno"
        SelectProps={{
          native,
        }}
      >
        {years}
      </TextField>
    );
  };

  numToMonths = (index) => {
    var month_name = "";
    switch (index) {
      case 1:
        month_name = "Gennaio";
        break;
      case 2:
        month_name = "Febbraio";
        break;
      case 3:
        month_name = "Marzo";
        break;
      case 4:
        month_name = "Aprile";
        break;
      case 5:
        month_name = "Maggio";
        break;
      case 6:
        month_name = "Giugno";
        break;
      case 7:
        month_name = "Luglio";
        break;
      case 8:
        month_name = "Agosto";
        break;
      case 9:
        month_name = "Settembre";
        break;
      case 10:
        month_name = "Ottobre";
        break;
      case 11:
        month_name = "Novembre";
        break;
      case 12:
        month_name = "Dicembre";
        break;
      default:
        month_name = "";
    }
    return month_name;
  };

  render() {
    const {
      convTerms,
      convName,
      convSurname,
      convAge,
      convMail,
      invalidMailText,
      invalidPasswordText,
      convPass,
      convGender,
      name,
      surname,
      mail,
      //cMail,
      password,
      cPassword,
      gender,
      terms,
      marketing,
      //done,
    } = this.state;

    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col">
            <div>
              <legend className="text-center">
                <div className="row items-align-center">
                  <div className="col-12 thipi-text-h2">Entra in Thipibo</div>
                  {/* <div className="col-12 thipi-text-h4">
                        inizia subito a sfruttare i nostri servizi
                      </div> */}
                </div>
              </legend>

              <div className="text-center pb-3">{this.props.children}</div>
              <form action="#" method="post" className="form">
                <div className="row py-1">
                  <div className="py-1 col-sm-6 col-md-6">
                    <TextField
                      variant="outlined"
                      size="small"
                      name="firstname"
                      label="Nome"
                      type="text"
                      autoComplete="given-name"
                      onChange={this.checkNameState}
                      value={name}
                      fullWidth
                      required
                      error={!convName}
                      helperText={!convName ? "Nome non valido" : null}
                    />
                  </div>
                  <div className="py-1 col-sm-6 col-md-6">
                    <TextField
                      variant="outlined"
                      size="small"
                      name="lastname"
                      label="Cognome"
                      type="text"
                      autoComplete="given-name"
                      onChange={this.checkSurnameState}
                      value={surname}
                      fullWidth
                      required
                      error={!convSurname}
                      helperText={!convSurname ? "Cognome non valido" : null}
                    />
                  </div>
                </div>
                <div className="py-2">
                  <TextField
                    variant="outlined"
                    size="small"
                    name="youremail"
                    label="E-mail"
                    type="email"
                    autoComplete="username"
                    onChange={this.checkMailState}
                    value={mail}
                    fullWidth
                    required
                    error={!convMail}
                    helperText={!convMail ? invalidMailText : null}
                  />
                </div>
                <div className="pt-2 pb-1">
                  <TextField
                    variant="outlined"
                    size="small"
                    name="password"
                    label="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={this.checkPasswordState}
                    value={password}
                    fullWidth
                    required
                    error={!convPass}
                  />
                </div>
                <div className="py-1">
                  <TextField
                    variant="outlined"
                    size="small"
                    name="password"
                    label="Conferma Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={this.checkCPasswordState}
                    value={cPassword}
                    fullWidth
                    required
                    error={!convPass}
                    helperText={!convPass ? invalidPasswordText : null}
                  />
                </div>
                <label className="thipi-text-h4">Data di nascita</label>
                <div className="row">
                  <div className="py-1  col-xs-4 col-md-4 ">
                    {this.showDays(convAge, this.state.month, this.state.year)}
                  </div>
                  <div className="py-1 col-xs-4 col-md-4">
                    {this.showMonths(convAge)}
                  </div>
                  <div className="py-1 col-xs-4 col-md-4">
                    {this.showYears(convAge)}
                  </div>
                </div>
                <div className="form-div">
                  <FormControl error={!convGender} component="fieldset">
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="gender"
                      value={gender}
                      onChange={this.checkGenderState}
                    >
                      <FormControlLabel
                        value="m"
                        control={<Radio color="primary" />}
                        label="Uomo"
                      />
                      <FormControlLabel
                        value="f"
                        control={<Radio color="primary" />}
                        label="Donna"
                      />
                    </RadioGroup>
                    <FormHelperText>
                      {!convGender ? "Seleziona una opzione" : null}
                    </FormHelperText>
                  </FormControl>
                </div>

                <div className="form-div">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={terms}
                        onChange={this.checkTermsState}
                        name="check_terms"
                        color="primary"
                      />
                    }
                    label={
                      <>
                        <span>
                          Dichiaro presa visione{" "}
                          <Link
                            to="/privacy-policy"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            privacy policy
                          </Link>{" "}
                          e{" "}
                          <Link
                            to="/terms"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            termini e condizioni
                          </Link>
                        </span>

                        {!convTerms && (
                          <div className="invalid-feedback form-isInvalid">
                            Devi accettare termini e condizioni di utilizzo per
                            proseguire
                          </div>
                        )}
                      </>
                    }
                  />
                </div>
                <div className="form-div">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={marketing}
                        onChange={this.checkMarketingState}
                        name="check_marketing"
                        color="primary"
                      />
                    }
                    label={
                      <span>
                        Acconsento all'accesso dei miei dati per finalità di
                        marketing
                      </span>
                    }
                  />
                </div>

                <div className="pt-3">
                  {!this.state.saving && (
                    <Button
                      className="btn-block"
                      type="button"
                      disabled={this.state.saving}
                      ref={(btnSignup) => {
                        this.btnSignup = btnSignup;
                      }}
                      onClick={() => {
                        this.submitNewUser();
                      }}
                    >
                      Iscriviti
                    </Button>
                  )}
                  {this.state.saving && (
                    <Button
                      className="btn-block nopadding"
                      type="button"
                      disabled={this.state.saving}
                      ref={(btnSignup) => {
                        this.btnSignup = btnSignup;
                      }}
                      onClick={() => {}}
                    >
                      <Loader orange={false} /> Attendere
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withWidthHook,
  connect(mapStateToProps, mapDispatchToProps)
)(Signup);
