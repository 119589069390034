import {
  SET_CONVERSATION,
  ADD_TO_CONVERSATION,
  SET_CONVERSATION_PREVIEW,
  UPDATE_PREVIEW,
  READ_PREVIEW,
  READ_MESSAGE,
} from "../constants/action-conversation-types";
import { RESET_REDUX } from "../constants/action-general-types";
import produce from "immer";

const initialState = {
  conversations: [],
  previews: [],
};

const conversationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;

    // eslint-disable-next-line default-case
    switch (type) {
      case SET_CONVERSATION:
        payload.forEach((element) => {
          var newElement;
          if (element.pointer) newElement = element;
          else
            newElement = {
              conversation_id: element.conversation_id,
              messages: element.messages,
              pointer: 10,
            };
          const index = draft.conversations.findIndex(
            (e) => e.conversation_id == element.conversation_id
          );
          if (index == -1) {
            draft.conversations.push(newElement);
          } else {
            draft.conversations[index] = newElement;
          }
        });
        return;
      case ADD_TO_CONVERSATION:
        payload.forEach((element) => {
          const index = draft.conversations.findIndex(
            (e) => e.conversation_id == element.conversation_id
          );
          if (index != -1) {
            draft.conversations[index].messages.push(element.message);
            draft.conversations[index].pointer =
              draft.conversations[index].pointer + 1;
          }
        });
        return;
      case SET_CONVERSATION_PREVIEW:
        payload.forEach((element) => {
          const index = draft.previews.findIndex((e) => e.id == element.id);
          if (index == -1) {
            draft.previews.push(element);
          } else {
            draft.previews[index] = element;
          }
        });
        return;
      case UPDATE_PREVIEW:
        const update_index = draft.previews.findIndex(
          (e) => e.id == payload.conversation.conversation_id
        );
        if (update_index != -1) {
          draft.previews[update_index].text = payload.conversation.message.text;
          draft.previews[update_index].timestamp =
            payload.conversation.message.timestamp;
          if (!payload.read) {
            draft.previews[update_index].read_mark = false;
          } else draft.previews[update_index].readers = []; // i visualizzato sono sulla preview!
        }
        return;
      case READ_PREVIEW:
        const read_index = draft.previews.findIndex((e) => e.id == payload);
        if (read_index != -1) {
          draft.previews[read_index].read_mark = true;
        }
        return;
      case READ_MESSAGE:
        //console.log("inbox reducer", payload);
        const message_index = draft.previews.findIndex(
          (e) => e.id == payload.conv_id
        );
        if (message_index != -1) {
          //console.log("inbox reducer 2", draft.previews[message_index]);
          if (state.previews[message_index].readers)
            draft.previews[message_index].readers.push(payload.user_id);
          else draft.previews[message_index].readers = [payload.user_id];
        }
        return;
      case RESET_REDUX:
        return initialState;
    }
  });

export default conversationReducer;
