export const LOGGEDIN = "GENERAL:LOGGEDIN";
export const MISSING_MODAL = "GENERAL:MISSING_MODAL";
export const PUSH_NOTIF_MODAL = "GENERAL:PUSH_NOTIF_MODAL";
export const SET_INVITATION = "GENERAL:SET_INVITATION";
export const ADD_INVITATION = "GENERAL:ADD_INVITATION";
export const SET_NEWTENANTS = "GENERAL:SET_NEWTENANTS";
export const SET_NEWADS = "GENERAL:SET_NEWADS";
export const SET_SELECTEDROOM = "GENERAL:SET_SELECTEDROOM";
export const SET_NREAD = "GENERAL:SET_NREAD";
export const SET_STEP = "GENERAL:SET_STEP";
export const SET_INAPP_WARNING = "GENERAL:SET_INAPP_WARNING";
export const SET_CITIES = "GENERAL:SET_CITIES";
export const RESET_REDUX = "RESET_REDUX";
