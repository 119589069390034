import {
  SET_USER,
  SET_LOGGED,
  SET_AGENDA,
  SET_NUM_SUPERPING,
  SET_NOTE,
  //CHANGE_INFO,
  CHANGE_RESEARCH_INFO,
  CHANGE_PERSONAL_INFO,
  CHANGE_CURR_COND,
  CHANGE_PERSONAL_ELEMENTS,
  CHANGE_TENANTS_NUMBER,
  //CHANGE_TENANT,
  CHANGE_IMAGE,
  CHANGE_COVER_IMAGE,
  CHANGE_VIDEO,
  CHANGE_TEMPLATE,
  CHANGE_SETTINGS,
  SET_HELP_SEEN,
  SET_INVITE_HELP_SEEN,
  SET_PING_HELP_SEEN,
  SET_SUPERPING_HELP_SEEN,
  SET_SHARE_HELP_SEEN,
  SET_NEW_USER,
  SET_TEMP_HOME,
  SET_PROFILATION,
} from "../constants/action-user-types";

export const setUser = (user) => ({ type: SET_USER, payload: user });
//export const userChangeInfoElements = infoElements => ({ type: CHANGE_INFO, payload: infoElements});
export const userChangeResearchElements = (researchElements) => ({
  type: CHANGE_RESEARCH_INFO,
  payload: researchElements,
});
export const setLogged = (logged) => ({ type: SET_LOGGED, payload: logged });
export const setAgenda = (entries) => ({ type: SET_AGENDA, payload: entries });
export const setNumSuperping = (num) => ({
  type: SET_NUM_SUPERPING,
  payload: num,
});
export const setNote = (entry) => ({ type: SET_NOTE, payload: entry });
export const userChangePersonalElements = ({
  weekend,
  sport,
  alcol,
  smoke,
  animals,
}) => ({
  type: CHANGE_PERSONAL_ELEMENTS,
  payload: { weekend, sport, alcol, smoke, animals },
});
export const userChangePersonalInfo = ({
  relationship,
  education,
  description,
  location,
}) => ({
  type: CHANGE_PERSONAL_INFO,
  payload: { relationship, education, description, location },
});
export const userChangeCurrentCondition = ({
  type,
  studyplace,
  workplace,
}) => ({ type: CHANGE_CURR_COND, payload: { type, studyplace, workplace } });
export const userChangeTenantsNumber = (index) => ({
  type: CHANGE_TENANTS_NUMBER,
  payload: index,
});

//export const userChangeTenants = (gender,type,age,id) => ({ type: CHANGE_TENANT, payload: {gender,type,age,id} });
export const userChangeImage = (profile_image, profile_image_webp) => ({
  type: CHANGE_IMAGE,
  payload: { profile_image, profile_image_webp },
});
export const userChangeCoverImage = (cover_image, cover_image_webp) => ({
  type: CHANGE_COVER_IMAGE,
  payload: { cover_image, cover_image_webp },
});
export const userChangeVideo = (videoId) => ({
  type: CHANGE_VIDEO,
  payload: videoId,
});
export const userChangeTemplate = (template) => ({
  type: CHANGE_TEMPLATE,
  payload: template,
});
export const userSettings = (settings) => ({
  type: CHANGE_SETTINGS,
  payload: settings,
});
export const userHelpSeen = (helpSeen) => ({
  type: SET_HELP_SEEN,
  payload: helpSeen,
});
export const userInviteHelpSeen = (bool) => ({
  type: SET_INVITE_HELP_SEEN,
  payload: bool,
});
export const userPingHelpSeen = (bool) => ({
  type: SET_PING_HELP_SEEN,
  payload: bool,
});
export const userSuperpingHelpSeen = (bool) => ({
  type: SET_SUPERPING_HELP_SEEN,
  payload: bool,
});
export const userShareHelpSeen = (bool) => ({
  type: SET_SHARE_HELP_SEEN,
  payload: bool,
});
export const userSetNew = (bool) => ({ type: SET_NEW_USER, payload: bool });
export const userSetTempHome = (tempHome) => ({
  type: SET_TEMP_HOME,
  payload: tempHome,
});

export const userSetProfilation = (user) => ({
  type: SET_PROFILATION,
  payload: user,
});
