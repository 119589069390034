import React from "react";
import { render } from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { Provider } from "react-redux";
import configureStore from "./store";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import config from "./config";
//import { IonApp } from '@ionic/react';
import { registerSW } from "virtual:pwa-register";

const intervalMS = 60 * 60 * 1000; // 1 hour

registerSW({
  immediate: true,
  onRegistered(r) {
    r &&
      setInterval(() => {
        console.log("Checking for updates for SW...");
        r.update();
      }, intervalMS);
  },
});

config.app.host !== "localhost" &&
  Sentry.init({
    dsn: "https://bde1935b42df4485a1b66a353f8b7393@o1164148.ingest.sentry.io/4504162975940608",
    integrations: [],
    environment: config.app.host === "thipibo.com" ? "production" : "testing",
  });

const store = configureStore();
export { store };

//const loader = document.getElementById("loader");
const rootElement = document.getElementById("root");
//const hideLoader = () => loader.classList.add("loader--hide");
const hideLoader = () => {};

const renderApp = (Component) => {
  return render(
    <Provider store={store}>
      <BrowserRouter>
        <Component onMount={hideLoader} />
      </BrowserRouter>
    </Provider>,
    rootElement
  );
};

renderApp(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorker.register({
//   onUpdate: (registration) => {
//     const waitingServiceWorker = registration.waiting;

//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener("statechange", (event) => {
//         if (event.target.state === "activated") {
//           window.location.reload();
//         }
//       });
//       waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
//     }
//   },
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
