import {
  LOGGEDIN,
  MISSING_MODAL,
  PUSH_NOTIF_MODAL,
  SET_INVITATION,
  ADD_INVITATION,
  SET_NEWTENANTS,
  SET_NEWADS,
  SET_SELECTEDROOM,
  SET_NREAD,
  SET_INAPP_WARNING,
  SET_STEP,
  RESET_REDUX,
  SET_CITIES,
} from "../constants/action-general-types";
import produce from "immer";

const initialState = {
  isAuthenticating: true,
  hasMissingModalOpened: false,
  hasPushNotifModalOpened: false,
  invitationLists: [],
  newTenants: [],
  tenCount: 0,
  newAds: [],
  selectedRoom: -1,
  nread: 0,
  inAppWarning: false,
  profilationStep: 0,
  cities: [],
};

const generalReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    let index;
    // eslint-disable-next-line default-case
    switch (type) {
      case LOGGEDIN:
        draft.isAuthenticating = false;
        return;
      case MISSING_MODAL:
        draft.hasMissingModalOpened = true;
        return;
      case PUSH_NOTIF_MODAL:
        draft.hasPushNotifModalOpened = true;
        return;
      case SET_INVITATION:
        index = draft.invitationLists.findIndex(
          (e) => e.ads_id == payload.ads_id
        );
        if (index === -1) {
          draft.invitationLists.push(payload);
        } else {
          draft.invitationLists[index] = payload;
        }
        return;
      case ADD_INVITATION:
        index = draft.invitationLists.findIndex(
          (e) => e.ads_id == payload.ads_id
        );
        if (index !== -1)
          draft.invitationLists[index].invitations.unshift(payload.invitation);
        return;
      case SET_CITIES:
        draft.cities = payload;
        return;
      case SET_NEWADS:
        payload.forEach((element) => {
          draft.newAds[element.advertise._id] = element;
        });
        return;
      case SET_NEWTENANTS:
        draft.newTenants = payload.users;
        draft.tenCount = payload.count;
        return;
      case SET_INAPP_WARNING:
        draft.inAppWarning = payload;
        return;
      case SET_SELECTEDROOM:
        draft.selectedRoom = payload;
        return;
      case SET_NREAD:
        draft.nread = payload;
        return;
      case SET_STEP:
        draft.profilationStep = payload;
        return;
      case RESET_REDUX:
        draft.isAuthenticating = false;
        draft.hasMissingModalOpened = false;
        draft.hasPushNotifModalOpened = false;
        draft.invitationLists = [];
        //draft.newTenants = [];
        //draft.newAds = [];
        draft.selectedRoom = -1;
        draft.nread = 0;
        draft.inAppWarning = false;
        draft.cities = [];
        return;
    }
  });
export default generalReducer;
