import produce from "immer";
import React from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import * as dateFns from "date-fns";
import { fetcher } from "./utility";
import { eventGA } from "./TrackerGA";
import Notification from "./HistoryComponents/Notification";

import { legacyParse, convertTokens } from "@date-fns/upgrade/v2";

export function reload(read) {
  var notifs = this.props.home.notifications;
  var nsize = size(notifs);
  var nread = 0;
  var currTime = 9999999999999;
  if (nsize > 0) {
    if (nsize > 1) {
      notifs = produce(notifs, (draft) => {
        draft.sort((a, b) => {
          if (a && b) return b.timestamp - a.timestamp;
        });
      });
    }
    notifs.forEach((n) => {
      if (n) {
        if (!n.read_mark) nread++;
        currTime = Math.min(currTime, n.timestamp);
      }
    });

    this.setState(
      produce((draft) => {
        draft.notification = notifs;
      })
    );
    this.setState({ nsize: nsize });
    this.setState({ nread: nread });
    this.props.setNread(nread);

    this.setState(
      produce((draft) => {
        draft.timestart = currTime;
      })
    );
    // console.log("size ", nsize, this.state.notification, notifs);
    if (read) setAsRead.call(this);
  }
}

export function showLoadingIcon() {
  if (
    (this.state.nsize > 0 &&
      this.state.isInitial &&
      this.props.user.logged &&
      !this.state.stop) ||
    this.state.loading
  ) {
    return (
      <div className="row justify-content-md-center">
        <div className="col text-center">
          <Loader />
        </div>
      </div>
    );
  } else return;
}

export function convertNotif(notifs) {
  var array = notifs;
  var newArray = [];
  array.forEach((not) => {
    var newElement = {
      slot: dateFns.format(
        legacyParse(new Date(not.month, not.day, not.hour, not.minute)),
        convertTokens("MM/DD-HH:MM")
      ),
      name: not.name,
      profile_image: not.profile_image,
      profile_image_webp: not.profile_image_webp,
      state: not.state,
      user_id: not.user_id,
      home_id: not.home_id,
    };
    newArray.push(newElement);
  });
  return newArray;
}

export function setAsRead() {
  if (!this.state.marked && this.state.nread > 0) {
    var notifications_id = [];
    this.state.notification.forEach((n) => {
      if (n) notifications_id.push(n.id);
    });
    fetcher({
      path: "/api/markNotAsRead",
      credentials: true,
      method: "POST",
      body: { notifications_id },
    })
      .then((res) => {
        if (!res.success) console.log(res.error);
        else {
          /*var temp_notification = this.state.notification
      temp_notification.forEach(n => {
        n.read_mark = true
      })
      this.setState({notification: temp_notification})*/
          this.setState({ marked: true });
          this.props.homeReadNotifications();
        }
      })
      .catch((e) => console.log("Error during Fetch:", e));
  }
}

export function acceptChat(event, not) {
  event.cancelBubble = true;
  event.stopPropagation();
  event.preventDefault();
  var state = "chatAccepted";

  fetcher({
    path: "/api/acceptMessageRequest",
    credentials: true,
    method: "POST",
    body: { not },
  })
    .then((res) => {
      if (!res.success) console.log(res.error);

      var index = getIndex(this.state.notification, not, this.state.nsize);

      //console.log("bug", res.notifications, index, this.state.notification)

      if (index !== -1) {
        var date = new Date();
        var time = date.getTime();
        this.setState(
          produce((draft) => {
            draft.notification[index].state = state;
            draft.notification[index].timestamp = time;
          })
        );

        this.props.homeSetNotificationState(
          this.state.notification[index].id,
          state
        );
      }

      eventGA(
        "COMPORTAMENTO",
        "Accetta_chat",
        "Home_" + this.props.home._id,
        this.props.user.city
      );

      this.props.emitMessage(
        "Message_accepted",
        convertToSend.call(this, not, state, "", res.id)
      );

      if (size(res.myConv) > 0) this.props.setConversationPreview(res.myConv);
      var convMessage = {
        conv: res.sendConv,
        user_id: not.user_id,
      };
      if (size(res.sendConv) > 0)
        this.props.emitMessage("New_conversation", convMessage);
      //  this.setState({notifications[0]: res.notification})
    })
    .catch((e) => {
      eventGA(
        "ERRORE",
        "Accetta_chat",
        "Home_" + this.props.home._id,
        this.props.user.city
      );
      console.log("Error during Fetch:", e);
    });
}

export function changeReqState(event, not, state, motivation) {
  event.cancelBubble = true;
  event.stopPropagation();
  event.preventDefault();

  fetcher({
    path: "/api/changeRequest",
    credentials: true,
    method: "POST",
    body: { not, state, motivation },
  })
    .then((res) => {
      if (!res.success) console.log(res.error);

      if (res.notifications) {
        this.props.homeSetNotifications(res.notifications);
      }
      var index = getIndex(this.state.notification, not, this.state.nsize);

      //console.log("bug", res.notifications, index, this.state.notification)

      if (index !== -1) {
        var date = new Date();
        var time = date.getTime();
        this.setState(
          produce((draft) => {
            draft.notification[index].state = state;
            draft.notification[index].timestamp = time;
          })
        );

        this.props.homeSetNotificationState(
          this.state.notification[index].id,
          state
        );
      }

      if (state == "accepted")
        eventGA(
          "COMPORTAMENTO",
          "Accetta_appuntamento",
          "Home_" + this.props.home._id,
          this.props.user.city
        );
      if (state == "refused")
        eventGA(
          "COMPORTAMENTO",
          "Rifiuta_appuntamento",
          "Home_" + this.props.home._id,
          this.props.user.city
        );

      var refsize = size(res.id_refused);
      if (refsize > 0) {
        var i;
        for (i = 0; i < refsize; i++) {
          this.props.emitMessage(
            "Accepted_or_refused",
            convertToSend.call(
              this,
              not,
              state,
              motivation,
              res.id,
              res.id_refused[i],
              res.id_users_refused[i],
              res.user_name
            )
          );
        }
      }

      var slot = {
        hour: not.hour,
        month: not.month,
        day: not.day,
        minute: not.minute,
        state: state,
        id: not.user_id,
      };
      this.props.homeAddUserInSlot(slot);
      this.props.emitMessage(
        "Accepted_or_refused",
        convertToSend.call(this, not, state, motivation, res.id)
      );
      if (state === "tenant-accepted") {
        var date = new Date();
        const now = date.getTime();
        var mynote = {
          ads_id: not.ads_id,
          day: null,
          home_id: not.home_id,
          hour: null,
          id: not.id,
          minute: null,
          month: null,
          name: res.user_name,
          profile_image: not.profile_image,
          profile_image_webp: not.profile_image_webp,
          read_mark: false,
          state: "tenant-accepted",
          timestamp: now,
          user_id: this.props.user._id,
        };
        this.props.homeAddNotification(mynote);
      }
      if (size(res.myConv) > 0) this.props.setConversationPreview(res.myConv);
      var convMessage = {
        conv: res.sendConv,
        user_id: not.user_id,
      };
      if (size(res.sendConv) > 0)
        this.props.emitMessage("New_conversation", convMessage);
      //  this.setState({notifications[0]: res.notification})
    })
    .catch((e) => {
      if (state == "accepted")
        eventGA(
          "ERRORE",
          "Accetta_appuntamento",
          "Home_" + this.props.home._id,
          this.props.user.city
        );
      if (state == "refused")
        eventGA(
          "ERRORE",
          "Rifiuta_appuntamento",
          "Home_" + this.props.home._id,
          this.props.user.city
        );
      console.log("Error during Fetch:", e);
    });
}

function changeMateReqState(event, not, state) {
  event.cancelBubble = true;
  event.stopPropagation();
  event.preventDefault();

  fetcher({
    path: "/api/changeMateRequest",
    credentials: true,
    method: "POST",
    body: { not, state },
  })
    .then((res) => {
      if (!res.success) console.log(res.error);

      var index = getIndex(this.state.notification, not, this.state.nsize);
      if (index !== -1) {
        var date = new Date();
        var time = date.getTime();
        this.setState(
          produce((draft) => {
            draft.notification[index].state = res.state;
            draft.notification[index].timestamp = time;
          })
        );

        this.props.homeSetNotificationState(
          this.state.notification[index].id,
          res.state
        );
      }

      if (state == "accepted") {
        eventGA(
          "COMPORTAMENTO",
          "Accetta_inquilino",
          "User_" + this.props.user._id,
          this.props.user.city
        );
        window.location.reload();
      }
      if (state == "refused")
        eventGA(
          "COMPORTAMENTO",
          "Rifiuta_inquilino",
          "User_" + this.props.user._id,
          this.props.user.city
        );

      this.props.emitMessage(
        "Accepted_or_refused",
        convertToSend.call(this, not, state, "", res.id)
      );
    })
    .catch((e) => console.log("Error during Fetch:", e));
}

export function renderNotifications(list) {
  if (this.state.nsize > 0) {
    var notifs = [];
    const filterStates = [
      "tenant-pending",
      "tenant-refused",
      "invite",
      "roommate-invalid",
    ];
    const conventionalStates = [
      "accepted",
      "refused",
      "pending",
      "chatPending",
      "chatAccepted",
      "tenant-pending",
      "tenant-accepted",
      "tenant-refused",
      "invite",
      "roommate-invite",
      "roommate-accepted",
      "roommate-refused",
      "roommate-invalid",
    ];
    this.state.notification.forEach((n, i) => {
      if (n) {
        var id;
        if (n.name) {
          if (n.user_name && n.user_surname) {
            id = `${n.user_name}.${n.user_id}`;
          } else {
            var numBlank = n.name.split(" ").length - 1;
            var fullName = n.name.split(" ", numBlank + 1);
            if (numBlank === 1) id = fullName[0] + "." + n.user_id;
            if (numBlank === 2)
              id = fullName[0] + " " + fullName[1] + "." + n.user_id;
            if (numBlank === 3)
              id = fullName[0] + " " + fullName[1] + "." + n.user_id;
          }
        } else id = 0;
        if (
          n.state === "suggestion" ||
          (n.state === "invite" && n.user_id !== this.props.user._id)
        ) {
          notifs.push(
            <Notification
              key={i}
              user_id={id}
              profile_image={n.profile_image}
              profile_image_webp={n.profile_image_webp}
              timestamp={n.timestamp}
              read={n.read_mark}
              state={n.state}
            >
              {n.state === "invite" && this.state.mobile && (
                <div className="col-12">
                  <span className="notif-text-name">{n.name}</span> ti ha
                  contattato, controlla{" "}
                  <Link
                    to={{
                      pathname: "/inbox",
                    }}
                    activeclassname="active"
                  >
                    le conversazioni
                  </Link>
                </div>
              )}
              {n.state === "invite" && !this.state.mobile && (
                <div className="col-12">
                  <span className="notif-text-name">{n.name}</span> ti ha
                  contattato, controlla{" "}
                  <Link
                    to={
                      this.props.inbox.previews[0]
                        ? "/messages?&a=" + this.props.inbox.previews[0].id
                        : "/messages"
                    }
                    activeclassname="active"
                  >
                    le conversazioni
                  </Link>
                </div>
              )}
              {n.state === "suggestion" && (
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <span className="notif-text-name">{n.name}</span> ed altri
                      5 sono affini al tuo annuncio
                    </div>
                  </div>
                  <div className="row pt-2 justify-content-start thipi-text-h5">
                    <div className="col-auto h-100" cursor="pointer">
                      <Link to={"/inquilino/" + id}>
                        <button className="thipi-btn-color h-50">
                          Vedi Profilo
                        </button>
                      </Link>
                    </div>
                    <div className="col-auto h-100" cursor="pointer">
                      <Link
                        to={
                          "/cerca-inquilino/" +
                          this.props.home.city +
                          "?&a=" +
                          JSON.stringify(n.ads_id)
                        }
                      >
                        <button className="btn-myoutline h-50">
                          Vedi altri
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </Notification>
          );
        } else {
          if (
            !filterStates.includes(n.state) &&
            n.timestamp >= this.props.user.timestamp_home &&
            this.props.home._id !== -1 &&
            n.user_id !== this.props.user._id
          ) {
            //, n.timestamp>=this.props.user.timestamp_home
            notifs.push(
              <Notification
                key={i}
                user_id={id}
                profile_image={n.profile_image}
                profile_image_webp={n.profile_image_webp}
                timestamp={n.timestamp}
                read={n.read_mark}
                state={n.state}
              >
                {n.state === "pending" && (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <span className="notif-text-name">{n.name}</span> ha
                        inviato una richiesta di appuntamento per il{" "}
                        {parseDate(n)}
                      </div>
                    </div>
                    <div className="row pt-2 justify-content-start">
                      <div className="col-auto h-100" cursor="pointer">
                        <button
                          className="thipi-btn-color h-50"
                          onClick={(event) =>
                            changeReqState.call(this, event, n, "accepted", "")
                          }
                        >
                          Accetta
                        </button>
                      </div>
                      <div className="col-auto h-100" cursor="pointer">
                        <button
                          className="btn-myoutline h-50"
                          onClick={(event) =>
                            changeReqState.call(this, event, n, "refused", "")
                          }
                        >
                          Rifiuta
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {n.state === "roommate-invite" && (
                  <div className="col-12">
                    Hai chiesto a{" "}
                    <span className="notif-text-name">{n.name}</span> di essere
                    un nuovo inquilino, attendi la sua risposta.
                  </div>
                )}
                {n.state === "roommate-accepted" && (
                  <div className="col-12">
                    <span className="notif-text-name">{n.name}</span> è ora un
                    nuovo inquilino.
                  </div>
                )}
                {n.state === "roommate-refused" && (
                  <div className="col-12">
                    <span className="notif-text-name">{n.name}</span> ha
                    rifiutato di diventare un coinquilino.
                  </div>
                )}
                {n.state === "accepted" && (
                  <div className="col-12">
                    Hai un appuntamento con{" "}
                    <span className="notif-text-name">{n.name}</span> per il{" "}
                    {parseDate(n)}. Utilizza{" "}
                    <Link to="/agenda">la tua agenda</Link> per controllare gli
                    appuntamenti.
                  </div>
                )}
                {
                  n.state === "refused" && (
                    <div className="col-12">
                      Hai rifiutato{" "}
                      <span className="notif-text-name">{n.name}</span> per
                      l&apos;appuntamento del {parseDate(n)}
                    </div>
                  )
                  /*  cols.push(
          <div className="col-1 mycol">
            <button className="close" type="button" onClick = {event => this.remove(event, n)} data-toggle="modal">×</button>
          </div>)*/
                }
                {n.state === "chatPending" && (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <span className="notif-text-name">{n.name}</span>{" "}
                        vorrebbe inviarti un messaggio
                      </div>
                    </div>
                    <div className="row pt-2 justify-content-start">
                      <div className="col-auto h-100" cursor="pointer">
                        <button
                          className="thipi-btn-color h-50"
                          onClick={(event) => acceptChat.call(this, event, n)}
                        >
                          Accetta richiesta
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {n.state === "chatAccepted" && (
                  <div className="col-12">
                    Ora puoi rispondere a{" "}
                    <span className="notif-text-name">{n.name + " "}</span>
                    <Link to={"/inbox"} className="nounderline thipi-link pr-0">
                      controlla i tuoi messaggi
                    </Link>
                  </div>
                )}
                {/*(n.state === "tenant-accepted") && (
          <div className="col-12 mycol">
              <div className="row myrow">
                <div className="col-12 mycol">
                  {"Complimenti " + n.name + " è ora un nuovo coinquilino!"}
                </div>
              </div>
          </div>)*/}
                {n.state !== "invite" &&
                  n.state !== "tenant-accepted" &&
                  n.state !== "accepted" &&
                  n.state !== "refused" &&
                  n.state !== "pending" &&
                  n.state !== "chatPending" &&
                  n.state !== "chatAccepted" &&
                  n.state.substring(0, 8) !== "roommate" && (
                    <div className="col-12 mycol">{n.state}</div>
                  )}
              </Notification>
            );
          }
          if (
            (this.props.home._id === "-1" ||
              n.timestamp < this.props.user.timestamp_home ||
              n.user_id === this.props.user._id) &&
            n.name !== this.props.user.name + " " + this.props.user.surname
          ) {
            const currentNot = n;
            notifs.push(
              <Notification
                key={i}
                ads_id={n.ads_id}
                profile_image={n.profile_image}
                profile_image_webp={n.profile_image_webp}
                timestamp={n.timestamp}
                read={n.read_mark}
                state={n.state}
              >
                {n.state === "refused" && (
                  <div className="col-12">
                    <span className="notif-text-name">{n.name}</span> ha{" "}
                    {"rifiutato"} l&apos;appuntamento per il {parseDate(n)}.
                    Prova a cambiare orario.
                  </div>
                )}
                {
                  n.state === "accepted" && (
                    <div className="col-12">
                      <span className="notif-text-name">
                        Hai un appuntamento da {n.name}
                      </span>{" "}
                      per il {parseDate(n)}
                    </div>
                  )
                  /*  cols.push(
          <div className="col-1 mycol">
            <button className="close" type="button"  onClick = {event => this.remove(event, n)} data-toggle="modal">×</button>
          </div>) */
                }
                {n.state === "chatAccepted" && (
                  <div className="col-12">
                    <span className="notif-text-name">{n.name}</span> ha
                    accettato la richiesta,{" "}
                    <Link to={"/inbox"} className="nounderline thipi-link pr-0">
                      controlla i tuoi messaggi
                    </Link>
                  </div>
                )}
                {n.state === "pending" && (
                  <div className="col-12">
                    In attesa di risposta da{" "}
                    <span className="notif-text-name">{n.name}</span> per
                    l&apos;appuntamento del {parseDate(n)}
                  </div>
                )}
                {n.state === "chatPending" && (
                  <div className="col-12">
                    Hai inviato una richiesta di messaggio a{" "}
                    <span className="notif-text-name">{n.name}</span>
                  </div>
                )}
                {n.state === "tenant-pending" && (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <span className="notif-text-name">{n.name}</span> ti
                        vorrebbe come nuovo inquilino!
                      </div>
                    </div>
                    <div className="row pt-2 justify-content-start">
                      <div className="col-auto h-100" cursor="pointer">
                        <button
                          className="thipi-btn-color h-50"
                          onClick={() => {
                            this.handleCurrentNot(currentNot);
                            this.toggleAcceptModal(true);
                          }}
                        >
                          Accetta
                        </button>
                      </div>
                      <div className="col-auto h-100" cursor="pointer">
                        <button
                          className="btn-myoutline h-50"
                          onClick={() => {
                            this.handleCurrentNot(currentNot);
                            this.toggleRefuseModal(true);
                          }}
                        >
                          Rifiuta
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {n.state === "roommate-invite" && (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <span className="notif-text-name">{n.name}</span> ti ha
                        chiesto di diventare un inquilino.
                      </div>
                    </div>
                    <div className="row pt-2 justify-content-start">
                      <div className="col-auto h-100" cursor="pointer">
                        <button
                          className="thipi-btn-color h-50"
                          onClick={(event) =>
                            changeMateReqState.call(this, event, n, "accepted")
                          }
                        >
                          Accetta
                        </button>
                      </div>
                      <div className="col-auto h-100" cursor="pointer">
                        <button
                          className="btn-myoutline h-50"
                          onClick={(event) =>
                            changeMateReqState.call(this, event, n, "refused")
                          }
                        >
                          Rifiuta
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {n.state === "tenant-refused" && (
                  <div className="col-12">
                    Hai rifiutato l&apos;invito di{" "}
                    <span className="notif-text-name">{n.name}</span>
                    {", "}
                    continua a provare e troverai quello che cerchi!
                  </div>
                )}
                {n.state === "tenant-accepted" && (
                  <div className="col-12">
                    Congratulazioni per il tuo nuovo affitto! Speriamo tu abbia
                    una bella esperienza con{" "}
                    <span className="notif-text-name">{n.name}</span>
                  </div>
                )}
                {n.state === "roommate-accepted" && (
                  <div className="col-12">
                    <span className="notif-text-name">{n.name}</span> ti da il
                    benvenuto, ora condividete la gestione degli annunci.
                  </div>
                )}
                {n.state === "roommate-refused" && (
                  <div className="col-12">
                    Richiesta di far parte della casa di{" "}
                    <span className="notif-text-name">{n.name}</span> rifiutata
                  </div>
                )}
                {n.state === "roommate-invalid" && (
                  <div className="col-12">
                    Ci dispiace, l'invito di{" "}
                    <span className="notif-text-name">{n.name}</span> è stato
                    annullato.
                  </div>
                )}
                {!conventionalStates.includes(n.state) && (
                  <div className="col-12">{n.state}</div>
                )}
              </Notification>
            );
          }
        }
      }
    });
    return <React.Fragment>{notifs}</React.Fragment>;
  }
}

const parseDate = (notif) => {
  var parsed =
    notif.day +
    "/" +
    ("0" + notif.month).slice(-2) +
    " alle " +
    notif.hour +
    ":" +
    (notif.minute > 0 ? notif.minute : "00");
  return parsed;
};

const size = (obj) => {
  var size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
};

const getIndex = (array, not, nsize) => {
  for (var i = 0; i < nsize; i++) {
    if (
      array[i] &&
      array[i].day === not.day &&
      array[i].month === not.month &&
      array[i].year === not.year &&
      array[i].hour === not.hour &&
      array[i].minute === not.minute &&
      array[i].name === not.name
    )
      return i;
  }
  return -1;
};

function convertToSend(
  not,
  state,
  motivation,
  id,
  id_refused,
  id_user_refused,
  home_name
) {
  if (id_refused) {
    let date = new Date();
    const now = date.getTime();
    return {
      ads_id: not.ads_id,
      day: null,
      home_id: null,
      hour: null,
      id: id_refused,
      minute: null,
      month: null,
      name: this.props.user.name + " " + this.props.user.surname,
      profile_image: not.profile_image, // DA CAMBIARE
      profile_image_webp: not.profile_image_webp, // DA CAMBIARE
      read_mark: false,
      state:
        home_name +
        " ha trovato un inquilino. Continua a controllare gli annunci ed arricchisci il tuo profilo per avere più possibilità.",
      timestamp: now,
      user_id: id_user_refused,
    };
  } else {
    if (state === "chatAccepted") {
      return {
        ads_id: not.ads_id,
        day: "",
        home_id: not.home_id,
        hour: "",
        id: not.id,
        minute: "",
        month: "",
        name: this.props.user.name + " " + this.props.user.surname,
        profile_image: not.profile_image, // DA CAMBIARE
        profile_image_webp: not.profile_image_webp, // DA CAMBIARE
        read_mark: false,
        state: state,
        timestamp: not.timestamp,
        user_id: not.user_id,
      };
    }
    if (state === "accepted") {
      return {
        ads_id: not.ads_id,
        day: not.day,
        home_id: not.home_id,
        hour: not.hour,
        id: not.id,
        minute: not.minute,
        month: not.month,
        name: this.props.user.name + " " + this.props.user.surname,
        profile_image: not.profile_image, // DA CAMBIARE
        profile_image_webp: not.profile_image_webp, // DA CAMBIARE
        read_mark: false,
        state: state,
        timestamp: not.timestamp,
        user_id: not.user_id,
      };
    }
    if (state === "refused") {
      return {
        ads_id: not.ads_id,
        day: not.day,
        home_id: not.home_id,
        hour: not.hour,
        id: not.id,
        minute: not.minute,
        month: not.month,
        name: this.props.user.name + " " + this.props.user.surname,
        profile_image: not.profile_image, // DA CAMBIARE
        profile_image_webp: not.profile_image_webp, // DA CAMBIARE
        read_mark: false,
        state: state,
        timestamp: not.timestamp,
        user_id: not.user_id,
      };
    }
    if (state === "tenant-accepted") {
      let date = new Date();
      const now = date.getTime();
      return {
        ads_id: not.ads_id,
        day: null,
        home_id: not.home_id,
        hour: null,
        id: id,
        minute: null,
        month: null,
        name: "",
        profile_image: not.profile_image,
        profile_image_webp: not.profile_image_webp,
        read_mark: false,
        state:
          "Complimenti " +
          this.props.user.name +
          " " +
          this.props.user.surname +
          " è ora un nuovo coinquilino!",
        timestamp: now,
        user_id: not.user_id,
      };
    }
    if (state === "tenant-refused") {
      let date = new Date();
      const now = date.getTime();
      return {
        ads_id: not.ads_id,
        day: null,
        home_id: not.home_id,
        hour: null,
        id: id,
        minute: null,
        month: null,
        name: "",
        profile_image: this.props.user.profile_image,
        profile_image_webp: this.props.user.profile_image_webp,
        read_mark: false,
        state:
          this.props.user.name +
          " " +
          this.props.user.surname +
          " ha rifiutato il tuo invito dicendo: " +
          motivation,
        timestamp: now,
        user_id: not.user_id,
      };
    }
  }
}

/*
  remove = (event, not) => {
    event.cancelBubble = true;
    if (event.stopPropagation) event.stopPropagation();
    fetch(
      config.app.fullUrl +
        "/api/removeRequest",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
       body: { not })
      }
    )
      .then(res => res.json())
      .then(res => {
        if (!res.success) console.log(res.error);
        else {
          this.props.homeSetNotifications(res.notifications);
          var notif = this.state.notification;
          var newsize = this.state.nsize - 1;
          var index = this.getIndex(notif, not);
          notif.splice(index, 1);
          this.setState({ notification: notif });
          this.setState({ nsize: newsize });
        }
      });
  };*/
