import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers";
import socketMiddleware from "../middlewares/socketMiddleware";

const configureStore = () => {
  const store = createStore(rootReducer, applyMiddleware(socketMiddleware()));

  // if (process.env.NODE_ENV !== 'production') {
  //   if (module.hot) {
  //     module.hot.accept('../reducers', () => {
  //       store.replaceReducer(
  //         rootReducer,
  //         applyMiddleware(socketMiddleware()));
  //     });
  //   }
  // }

  return store;
};

export default configureStore;
